import { useEffect, useState } from "react";

const path = `${process.env.PUBLIC_URL}/assets/images/`;


const picsArr = [
  'title01.png',
  'title02.png',
  'title03.png',
  'title04.png',
  //'title05.png',
  'title06.png',
 // 'title05.png',
]

export default function TitleSlide() {


 
  const [pic, setpic] = useState(path + picsArr[0]);

  

  useEffect(() => {

    let numbred = 5;
 


    console.log(path + picsArr[0])
    let intervalID = setInterval(() => {
      if (numbred >= (picsArr.length -1)){
        numbred = 0;
      }else{
        numbred = numbred + 1;
      }
      
      setpic(path + picsArr[numbred]);
    }, 500);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return (
    <div className="title-slide">
      <img src={pic} />
    </div>
  );
}
