/*
import React, { useState, useEffect } from 'react'
import { useTrail, a, config } from '@react-spring/web'

const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children)
  console.log('control items', items)
  const trail = useTrail(items.length, {
   // config: { mass: 50, tension: 2000, friction: 200 },
   // config: config.wobble,
    config: config.gentle,
    delay: 0,//open ? 1000 : 0,
    opacity: open ? 1 : 0,
    translateY: open ? 0 : 100,
   // height: open ? 100 : 0,
    from: { 
        opacity: 0, 
        translateY: 100, 
        //height: 0 
    },
  })
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} style={style}>
          {items[index]}
        </a.div>
      ))}
    </div>
  )
}

export default function PrintControl({
    reveal, 
    prevBkgd, 
    exportSnapshot, 
    nextBkgd,
    selfieSize
}) {
  const [open, set] = useState(reveal)

  useEffect(() => {
      console.log('reveal', reveal)
    set(reveal)
  }, [reveal])

  return (
    <div className="button-bar print-bar show" style={{
        bottom: (window.innerHeight - selfieSize) / 2,
        width: selfieSize
      }}
      //  onClick={() => set(state => !state)}
        >
      
        <button className="arrow-btn down" onClick={exportSnapshot}></button>
     
    </div>
  )
}
*/

import React, { useState, useEffect } from 'react'
import { useTrail, a, config } from '@react-spring/web'

const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children)
  console.log('control items', items)
  const trail = useTrail(items.length, {
   // config: { mass: 50, tension: 2000, friction: 200 },
   // config: config.wobble,
    config: config.gentle,
    delay: open ? 1000 : 0,
    opacity: open ? 1 : 0,
    scale: open ? 1 : 0,
    translateY: 0, 
   // translateY: open ? 0 : 100,
   // height: open ? 100 : 0,
    from: { 
        opacity: 0, 
        scale:0,
        translateY: 0, 
        //height: 0 
    },
  })
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} style={style}>
          {items[index]}
        </a.div>
      ))}
    </div>
  )
}

export default function PrintControl({
    reveal, 
    gameState,
    prevBkgd, 
    exportSnapshot, 
    closeSnapshot,
    onHover,
    nextBkgd,
    selfieSize
}) {
  const [open, set] = useState(reveal)

  useEffect(() => {
     // console.log('reveal', reveal)
      if (gameState==='polaroid'){
        set(true)
      }else{
        set(false)
      }
   
  }, [gameState])

  return (
    <div className="button-bar print-bar show" style={{
       // bottom: (window.innerHeight - selfieSize) / 2,
      //  width: selfieSize
      }}
      //  onClick={() => set(state => !state)}
        >
      <Trail open={open}>
  
        <button className="closeButton" onClick={closeSnapshot} ></button>
        {/*
        <div className="download-holder">
        <button 
          className="arrow-btn down" 
          onClick={exportSnapshot}
          onMouseEnter={() => onHover(true)}
          onMouseLeave={() => onHover(false)}>
          
        </button>
        </div>
        */}
        
      
      </Trail>
    </div>
  )
}


