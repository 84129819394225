/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useCallback, useRef, useState } from 'react'
import { useGLTF, Text, Float, RoundedBox, Sphere, Circle} from '@react-three/drei'
import { useSpring,useSpringRef, a, config, easings } from '@react-spring/three'
import { useFrame } from '@react-three/fiber'

import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'

import lerp from 'lerp'
//import niceColorPalette from 'nice-color-palettes/1000'
import { useWheel, useStore, glassesArray } from '../store'
import GoldGlasses from '../components/glasses/GoldGlasses'
import ThreeDeeGlasses from '../components/glasses/ThreeDeeGlasses'
import FakeGlowMaterial from '../components/FakeGlowMaterial'

export default function Screen({ wheelIndex, pos,total, objType, modelIdx,idx, opacity,playWhooshSFX,playBoopSFX, ...props }) {


  
const isMobile = useMediaQuery({ query: mediaQueries.mobile })
const isTablet = useMediaQuery({ query: mediaQueries.tablet })
const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

const setGlassesIndex = useWheel((s) => s.setGlassesIndex)

const assignWheelIndex = useWheel((s) => s.assignWheelIndex)

  const ref = useRef()
  const [hovered, setHovered] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
 // const { nodes } = useGLTF('/Screen.gltf')
  const scale = opacity === 1 ? 1.4 : 1
  const setWheelIndex = useWheel((s) => s.setWheelIndex)
  const glassesIndex = useWheel((s) => s.glassesIndex)

  const {setMainIndex, setPickedGlass} = useStore((state) => state.actions)
  const mainIndex = useStore((state) => state.mainIndex)
  const pickedGlass = useStore((state) => state.pickedGlass)

  const setGlassFollowActive = useWheel((s) => s.setGlassFollowActive)




  const toggle = useWheel((s) => s.toggleWheel)
  const wheelOpen = useWheel((s) => s.wheelOpen)
  const setPalette = useWheel((s) => s.setPalette)
  const [localOpen, setLocalOpen] = useState(wheelOpen)
/*
  useFrame(() => {
    const newScale = lerp(ref.current.scale.x, scale, 0.1)
    ref.current.scale.set(newScale, newScale, newScale)
  })
*/
/*
  const { yGlasses } = useSpring({

    yGlasses: (isMobile) ? 0 : (objType != 'glasses') ? -5 : 0.4,
   // yGlasses: 0,
    config: config.slow,
   // config: glassFollowActive ? config.slow :  { mass: 0.1, friction: 10, tension: 2000 } ,
  
  })
*/
  const { yPos } = useSpring({

    // yHead: (hovered || (mainIndex == modelIdx )) ? 0 : -6,
 
     from:{
     //  yPos: isMobile ? 1 :  -5
       yPos: isMobile ? 0 :  -2
     },
     to:{
      yPos: isMobile ? -0.5 :  0
     },
    // config: {
    
      //duration: 500,
      //easings: easings.easeOutQuint
    //},
     config: config.gentle,
     delay: (total - idx) *30,
    // delay: 1000
    // yGlasses: 0,
    // config: { mass: 0.1, friction: 10, tension: 200 } ,
   //  delay: ((mainIndex == modelIdx) && !hovered) ? 2000 : 0,
   /*
     config: {
       mass: 2,
       tension: 280,
       precision: 0.001,
       velocity: 0.009
     }
       */
    // config: glassFollowActive ? config.slow :  { mass: 0.1, friction: 10, tension: 2000 } ,
   
   })

  const { glassPos } = useSpring({

    // yHead: (hovered || (mainIndex == modelIdx )) ? 0 : -6,
 
     from:{
       glassPos: pos[0] - 55
     },
     to:{
      glassPos: pos[0]
     },
     
     config: {
    
      duration: 1500,
      easing: easings.easeOutQuad,
      precision: 0.0001
    },
    
    // config: config.slow,
     delay: 500
    // yGlasses: 0,
     //config: { mass: 10, friction: 100, tension: 200 } ,

     /*
     config:{
      mass: 2.0,
      tension: 66,
      friction: 25,
     // velocity: -0.2,
      precision: 0.001
    }

    */
   //  delay: ((mainIndex == modelIdx) && !hovered) ? 2000 : 0,
   /*
     config: {
       mass: 2,
       tension: 280,
       precision: 0.001,
       velocity: 0.009
     }
       */
    // config: glassFollowActive ? config.slow :  { mass: 0.1, friction: 10, tension: 2000 } ,
   
   })

  const { yHead } = useSpring({

   // yHead: (hovered || (mainIndex == modelIdx )) ? 0 : -6,

    from:{
      yHead: -6
    },
    to:{
      yHead: (hovered ||  mainIndex === modelIdx) ? 0 : -6
    },
   // yGlasses: 0,
   // config: { mass: 0.1, friction: 10, tension: 200 } ,
  //  delay: ((mainIndex == modelIdx) && !hovered) ? 2000 : 0,
    config: {
      mass: 2,
      tension: 280,
      precision: 0.001,
      velocity: 0.009
    }
   // config: glassFollowActive ? config.slow :  { mass: 0.1, friction: 10, tension: 2000 } ,
  
  })
 
 
  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
  }, [hovered])

  useFrame(() => {
  //  const newScale = lerp(ref.current.scale.x, scale, 0.1)
  //  ref.current.scale.set(newScale, newScale, newScale)
  })

  const handleClick = useCallback(
    (e) => {
     
     // setGlassesIndex(modelIdx);

     setGlassFollowActive(true)
     setMainIndex(modelIdx)
     setIsClicked(true)
     setPickedGlass(true)
     playBoopSFX()
     

     // alert('click' + modelIdx)
      e.stopPropagation()
      {/*
      if (wheelIndex) {
        if (wheelOpen) {
          setWheelIndex(wheelIndex)
          toggle()
        } else {
          if (opacity === 1) {
            toggle()
          }
        }
      }
    */}
    },[]
   // [setWheelIndex]
  )

  return (
    <a.group 
    //visible={opacity > 0.01} 
   // position-y={0.35}
   position-x={glassPos}
  //  position-y={yGlasses}
   // rotation-y={Math.PI/2}
    onClick={handleClick} ref={ref} 
    onPointerOver={(e) => void (e.stopPropagation(), setHovered(true), playWhooshSFX())}
    onPointerOut={() => setHovered(false)}
   // scale={[scale, scale, scale]} 
    {...props}>
      <a.group 
       // position={[0, -0.51, 1]} 
      //  position={glassPos}
      // position={[-30,0,0]}
      // position-x={glassPos}

          position-y={isMobile ? -0.5 :  0.5}
       
  //      position-y={yPos}
       // scale={[1.27, 1.27, 1.27]} 
        scale={isMobile ? .4 : .5}
     //  scale={scales}
       // dispose={null} 
        rotation={[0, 0 , 0]}
      >
     {/*
        <axesHelper scale={5} />
        <Text position={[0,1,0]} rotation-y={Math.PI/2}>{idx}</Text>
  */}
        <Float
          speed={4} // Animation speed, defaults to 1
          rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
          floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
        // floatingRange={[1, 10]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
        >
        {glassesArray[(modelIdx)] }
        
     
        </Float>
        <a.group 
        position-y={yHead}
        >

        <Sphere position={[0,0,-2]} >
          <meshStandardMaterial color={(mainIndex === modelIdx && !isMobile) ? 'hotpink' : 'white'  } />
        </Sphere>
        </a.group>


 
      </a.group>
{/*
      <RoundedBox
        args={[1, .6, 0.3]} // Width, height, depth. Default is [1, 1, 1]
        radius={0.05} // Radius of the rounded corners. Default is 0.05
        position={[0,0,-0.5]}
        smoothness={4} // The number of curve segments. Default is 4
        bevelSegments={4} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
        creaseAngle={0.4} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
      // {...meshProps} // All THREE.Mesh props are valid
      
      >

                <meshPhongMaterial color="#ffffff" attach="material-0"  />
                <meshPhongMaterial color={(mainIndex === idx) ? "#FF0048" : (hovered) ? "#FF9E00" : "#ffffff"} attach="material-1"  />
              
        
      </RoundedBox>
      */}

    </a.group> 
   
  )
}

//useGLTF.preload('/Screen.gltf')
