/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useCallback, useRef, useState, Suspense } from 'react'
import { useGLTF, Text, Float, RoundedBox, useTexture} from '@react-three/drei'
import { useSpring,useSpringRef, a, config, easings } from '@react-spring/three'
import { useFrame } from '@react-three/fiber'

import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'

import lerp from 'lerp'
//import niceColorPalette from 'nice-color-palettes/1000'
import { useWheel, useStore, backgroundArray } from '../store'


export default function Thumbnail({ 
  wheelIndex, 
  objType, 
  modelIdx,
  idx, 
  pos,
  total,
  opacity, 
  missedClick,
  playBoopSFX,
   ...props 
}) {


  
const isMobile = useMediaQuery({ query: mediaQueries.mobile })
const isTablet = useMediaQuery({ query: mediaQueries.tablet })
const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

const setGlassesIndex = useWheel((s) => s.setGlassesIndex)

const assignWheelIndex = useWheel((s) => s.assignWheelIndex)

  const ref = useRef()
  const [hovered, setHovered] = useState(false)
 // const { nodes } = useGLTF('/Screen.gltf')
  const scale = opacity === 1 ? 1.4 : 1
  const setWheelIndex = useWheel((s) => s.setWheelIndex)
  const glassesIndex = useWheel((s) => s.glassesIndex)

  const {setBkgdIndex, setPickedScene} = useStore((state) => state.actions)
  const bkgdIndex = useStore((state) => state.bkgdIndex)
  const pickedScene = useStore((state) => state.pickedScene)

  

  const texture1 = `${process.env.PUBLIC_URL}/assets/backgrounds/${backgroundArray[idx].url}`;

  const [colorMap] = useTexture([texture1])

  colorMap.offset.x = .05;
  colorMap.offset.y = .05;

/*
  const vSpring2 = useSpring({
    // loop: { reverse: true },
     from: { position: [0, -10, 0] },
     to: { position: [0, 0., 0] },
     onRest: () => {
      // animated(true)
       
     },
   //  duration: 1000,
     config: { mass: 50  , tension: 400, friction: 95 },
     delay:1000 * idx
   });
  */

   const [xGlasses] = useSpring(() => ({
    config: { mass: 10, tension: 400, friction: 100, precision: 0.0001 },
   // config: config.wobbly,
   // delay: 200,
    from: { position: [0, 0, 0] },
    to: { position: [0, 0, 0] },
   // onStart: () => playSound('headpopUP'),
  }))

  const { yGlasses } = useSpring({

    yGlasses: (isMobile) ? 0 : (objType !== 'scenes') ? -5 : 0.1,
  
   // yGlasses: 0,
    config: config.slow,
   // config: glassFollowActive ? config.slow :  { mass: 0.1, friction: 10, tension: 2000 } ,
  
  })

  const { yPos } = useSpring({


     from:{
      yPos: isMobile ? -1 :  -5
     },
     to:{
      yPos: isMobile ? -0.5 : 0
     },

     config: config.gentle,
     delay: (total - idx) *50,
    
   })

   const { glassPos } = useSpring({
 
     from:{
       glassPos: pos[0] - 30
     },
     to:{
      glassPos: pos[0]
     },
     
     config: {
    
      duration: 1200,
      easing: easings.easeInOutQuad,
      precision: 0.0001
    },
    delay: 200
    
   
   })

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
   // playBoopSFX();
  }, [hovered])

  useFrame(() => {
    const newScale = lerp(ref.current.scale.x, scale, 0.1)
    ref.current.scale.set(newScale, newScale, newScale)
  })
/*
  const handleClick = useCallback(
    (e) => {
     
 
     console.log('click bkgd', idx)
     setBkgdIndex(idx)
     setPickedScene(true)
     playBoopSFX()
     

     // alert('click' + modelIdx)
      e.stopPropagation()
     
    },
   // [setWheelIndex]
  )
*/
  const handleClick = useCallback(
    (e) => {
     
     // setGlassesIndex(modelIdx);

     setBkgdIndex(idx)
     setPickedScene(true)
     playBoopSFX()
     

     // alert('click' + modelIdx)
      e.stopPropagation()

    },[]
   // [setWheelIndex]
  )

  return (
    <a.group 
    //visible={opacity > 0.01} 
   // rotation-y={Math.PI/2}
   ref={ref}
   
   position-x={glassPos}
  // position-y={yGlasses}
   onClick={handleClick} 
    
    
   // scale={[scale, scale, scale]} 
    {...props}>
      <a.group 
       // position={[0, -0.51, 1]} 
       // position={[0, 0, 0]} 
        
      
       // scale={[1.27, 1.27, 1.27]} 
        scale={(isMobile)  ? 0.75 : 1}
       // position={xGlasses}
      //  position-y={yPos}
        position-y={isMobile ? -0.5 : 0.25}
        //dispose={null} 
        rotation={[0, 0 , 0]}
      >

     
          <RoundedBox
          args={[1, 1, 0.25]} // Width, height, depth. Default is [1, 1, 1]
          radius={0.05} // Radius of the rounded corners. Default is 0.05
          position={[0,0,-5]}
          smoothness={2} // The number of curve segments. Default is 4
          bevelSegments={2} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
          creaseAngle={0.4} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
          // {...meshProps} // All THREE.Mesh props are valid
          onPointerOver={(e) => void (e.stopPropagation(), setHovered(true))}
          onPointerOut={() => setHovered(false)}
         
    
         // onClick={(e) => void (e.stopPropagation(), handleClick(), playBoopSFX())}

          >
              <meshBasicMaterial color="#ffffff" attach="material-0"  map={colorMap}/>
              <meshStandardMaterial color={(bkgdIndex === idx) ? "#FF0048" : (hovered) ? "#FF9E00" : "#ffffff"} attach="material-1"  />
            
              
          </RoundedBox>
       
      
      </a.group>

      
    </a.group> 
   
  )
}

