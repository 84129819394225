// @ts-nocheck
import { useGLTF, useTexture, Plane, Box, Svg } from '@react-three/drei'
import React, { useRef, useEffect } from 'react'
import * as THREE from 'three'

const texture1 = `${process.env.PUBLIC_URL}/assets/images/glasses/page1c-frame.png`;
//const texture2 = `${process.env.PUBLIC_URL}/assets/images/glasses/page1c.png`;

const svg1 = `${process.env.PUBLIC_URL}/assets/images/glasses/page1c-frame2.svg`;
const svg2 = `${process.env.PUBLIC_URL}/assets/images/glasses/page1c.svg`;


export default function Model(props) {
  const group = useRef()
 const bodyTex  = useTexture(texture1)
/*
  const [bodyTex, armTex] = useTexture([texture1, texture2], ([texture1, texture2]) => {
   // texture1.map.premultiplyAlpha = false;
   // texture1.map.needsUpdate = true;
   // texture2.map.premultiplyAlpha = false;
   // texture2.map.needsUpdate = true;
    
  })

  useEffect(() => {
    armTex.premultiplyAlpha = false
    armTex.needsUpdate = true
    bodyTex.premultiplyAlpha = false
    bodyTex.needsUpdate = true
  }, [armTex, bodyTex])

*/
  return (
    <group ref={group} {...props} dispose={null} position-x={-0.05} position-y={0.3} scale={.95}>

      <Svg src={svg2} scale={.001} position={[-1.3,0.45,-2.24]} rotation={[0, -Math.PI/2, 0]}/>
      <Svg src={svg2} scale={.001} position={[1.4,0.45,-2.24]} rotation={[0, -Math.PI/2, 0]}/>
   

      <Plane args={[3.28, 1.115]} 
      position={[0,0,0]}
      >
         <meshBasicMaterial  map={bodyTex} side={THREE.DoubleSide} alphaTest={0.01}
         transparent 
         />
      </Plane>
  {/*
      <Plane args={[2.5, 0.705]} 
      position={[-1.4,0.05,-1.23]} 
      rotation={[0.05, -Math.PI/2, 0]}>
         <meshBasicMaterial transparent map={armTex} side={THREE.DoubleSide} alphaTest={0.01} />
      </Plane>
      <Plane args={[2.5, 0.705]}  
      position={[1.4,0.05,-1.23]} 
      rotation={[0.05, -Math.PI/2, 0]}>
         <meshBasicMaterial transparent map={armTex} side={THREE.DoubleSide} alphaTest={0.01} />
      </Plane>
  */}

    </group>
  )
}

