import ReactDOM from 'react-dom'
import React, { useEffect, useRef, forwardRef, useMemo, useState, useLayoutEffect, Suspense   } from 'react'
import { useThree, Canvas, useFrame } from '@react-three/fiber'
import { ContactShadows, Environment, RoundedBox, useGLTF, ScreenSizer, Mask, useMask, Bounds, PivotControls, OrbitControls, Stats,  MeshReflectorMaterial, useTexture, Box} from "@react-three/drei"
import { useSpring, useSprings, useChain, useSpringRef, config, easings } from '@react-spring/core'
import { a  } from '@react-spring/three'
//import { a as web } from '@react-spring/web'
import * as THREE from 'three'
import { mergeBufferGeometries } from 'three-stdlib';
//import * as BufferGeometryUtils from 'three-stdlib';
//import { mergeBufferGeometries } from 'three-stdlib/utils/BufferGeometryUtils'
import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'


import {useStore } from '../store'

//import { mergeBufferGeometries } from 'three-stdlib/utils/BufferGeometryUtils';


import diamondUrl from "../assets/star.glb"




const Sizer = ({squareSize}) => {

    const {scene, camera, size, viewport} = useThree()
  
    const {setVport, setDomSize, setOffsetY} = useStore((state) => state.actions)
  
  
    console.log('Sizer -size:', size)
    console.log('Sizer -squareSize:', squareSize)
  
    const matchRef = useRef();
  
    useEffect(() => {
  
     if (squareSize !== 0) MatchSquare(squareSize);
      setVport(viewport.height)
      setDomSize(size)
  
  
    }, []);
  
  const MatchSquare = (squareSize) => {
  
    const rect = squareSize
   // alert(rect)
    console.log(rect);
  
    const viewSize = size;
  
      // 1. Transform pixel units to camera's view units
      console.log('matchSquare: rect.width', rect.width)
      console.log('matchSquare: viewSize.width', viewSize.width)
      console.log('matchSquare: window.innerWidth', window.innerWidth)
  
     // const widthViewUnit = (rect.width * viewSize.width) / window.innerWidth;
     // const heightViewUnit = (rect.height * viewSize.height) / window.innerHeight;
  
      const widthViewUnit = rect.width  / window.innerWidth;
      const heightViewUnit = rect.height / window.innerHeight;
  
      let xViewUnit = (rect.left * viewSize.width) / window.innerWidth;
      let yViewUnit = (rect.top * viewSize.height) / window.innerHeight;
  
      console.log('matchSquare: size', size)
  
      console.log('matchSquare: widthViewUnit', widthViewUnit)
      console.log('matchSquare: heightViewUnit', heightViewUnit)
  
      // 2. Make units relative to center instead of the top left.
      xViewUnit = xViewUnit - viewSize.width / 2;
      yViewUnit = yViewUnit - viewSize.height / 2;
  
      console.log('matchSquare: xViewUnit', xViewUnit)
      console.log('matchSquare: yViewUnit', yViewUnit)
  
      // 3. Make the origin of the plane's position to be the center instead of top Left.
      let x = xViewUnit + widthViewUnit / 2;
      let y = -yViewUnit - heightViewUnit / 2;
  
      console.log('matchSquare: {x,y}', {x, y})
  /*
      matchRef.current.scale.x = widthViewUnit;
      matchRef.current.scale.y = heightViewUnit;
      matchRef.current.position.x = 0;//x;
      matchRef.current.position.y = 0;//y;
  */
    
  
  
  
   // setSquareSize(100)
  }
  /*
    const getViewSize = () => {
  
      console.log('camera', camera)
      console.log('size', size)
  
      const fovInRadians = ((camera.fov +1)* Math.PI) / 180;
      const height = Math.abs(
        camera.position.z * Math.tan(fovInRadians / 2) * 2
      );
  
      return { width: height *camera.aspect, height };
    }
  */
   // return null
  
   // Calculate the offset for -20% from the center on the Y axis
   const offsetY = 0;//(0.1 * viewport.height)/2; // -20% of the viewport height
   setOffsetY(offsetY)
  
    return (
  
      <mesh ref={matchRef} scale={[1,1,1]} position={[0,offsetY, 0]}>
        <planeBufferGeometry args={[2,2]}/>
        <meshPhongMaterial color="purple" opacity={1} colorWrite={false} />
      
      {/*  <meshBasicMaterial color="purple" transparent opacity={.25}/> */}
    
      </mesh>
    )
  }
  

  const MergedGeometry = () => {
    const geometry = useMemo(() => {
      // Create multiple geometries
      const box = new THREE.BoxGeometry(1, 1, 1);
      const sphere = new THREE.SphereGeometry(0.5, 32, 32);
      
      // Set positions of geometries
      sphere.translate(1, 0, 0); // Move the sphere so it doesn't overlap with the box
  
      // Merge the geometries into a single BufferGeometry
      const mergedGeometry = mergeBufferGeometries([box, sphere]);
  
      return mergedGeometry;
    }, []);
  
    return (
      <mesh geometry={geometry}>
      
        <meshStandardMaterial color="orange" />
                              
      </mesh>
    );
  };

function RoundedPlane({width = 1, height = 1}) {
    const geometry = useMemo(() => {
      const shape = new THREE.Shape();
      const x = -0.5, y = -0.5, radius = .1;
  
      shape.moveTo(x, y + radius);
      shape.lineTo(x, y + height - radius);
      shape.quadraticCurveTo(x, y + height, x + radius, y + height);
      shape.lineTo(x + width - radius, y + height);
      shape.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
      shape.lineTo(x + width, y + radius);
      shape.quadraticCurveTo(x + width, y, x + width - radius, y);
      shape.lineTo(x + radius, y);
      shape.quadraticCurveTo(x, y, x, y + radius);
  
      const geometry = new THREE.ExtrudeGeometry(shape, { depth: 0.1, bevelEnabled: false });
      return geometry;
    }, []);
  
    return (
      <mesh geometry={geometry}>
        <meshStandardMaterial color="lightblue" depthTest={false} 
                              colorWrite={true} />
      </mesh>
    );
  }
  
  
  const CircularMask = ( {previewActive, ...props}) => {

    const isMobile = useMediaQuery({ query: mediaQueries.mobile })
    const isTablet = useMediaQuery({ query: mediaQueries.tablet })
    const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

    const {scene, camera, size, viewport} = useThree()

    const pixelSize = isMobile ? Math.max(360, window.innerWidth - 60) + 9  : 619;
    const canvasHeight = window.innerHeight;
    const distance =5;
    const fov = 35;
    const scale = 2 * Math.tan((fov / 2) * (Math.PI / 180)) * distance * (pixelSize / canvasHeight)


    const offset = isMobile ? viewport.height/2 * .1 : 0;

    const { zFrame } = useSpring({

      zFrame: previewActive
        ? 1// + zAdjust
        : 0,
     // config: config.slow,
    //  delay: glassFollowActive ? 1000 : 0
     // config: glassFollowActive ? config.slow :  { mass: 0.1, friction: 10, tension: 2000 } ,
    
    })
  



    return(
    <a.group {...props} scale={zFrame}>
    {/*  <PivotControls offset={[0, 0, 1]} activeAxes={[true, true, false]} disableRotations depthTest={false}> */}

    {/*    <RoundedPlane width={scale} height={scale}/> */}

        <Mask id={1} position={[0, offset, 0]}>
          <planeGeometry args={[scale,scale]} />  
        
        
        
          
      {/* 
      <circleGeometry args={[1, 64]} />  
      

      */}

         
 

          {/*
          <ScreenSizer
                    scale={1} // scale factor
                  >
             
              
              <RoundedBox
                    //position={[0,0.5,1]}
                    //args={[2, 2, 0.01]}
                  
                            args={[610, 610, 0.01]} // Width, height, depth. Default is [1, 1, 1]
                            radius={15} // Radius of the rounded corners. Default is 0.05
                            smoothness={4} // The number of curve segments. Default is 4
                            bevelSegments={4} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
                            creaseAngle={0.4} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
                        >
                          
                    </RoundedBox>
               
                  </ScreenSizer>

                  */}
        </Mask>
     {/* </PivotControls> */}
    </a.group>
    )
  }


 function Diamonds({width, invert}) {
  const { size, viewport, gl, scene, camera, clock } = useThree()
  const stencil = useMask(1, invert)
  const model = useRef()


  const gltf = useGLTF(diamondUrl)

  // Create Fbo's and materials
  const [
   // envFbo, 
  //  backfaceFbo, 
  //  backfaceMaterial, 
    refractionMaterial
  ] = useMemo(() => {
   // console.log('gltf',gltf)
   // console.log('view', viewport.width)
  //  const envFbo = new WebGLRenderTarget(size.width, size.height)
  //  const backfaceFbo = new WebGLRenderTarget(size.width, size.height)
  //  const backfaceMaterial = new BackfaceMaterial()
   // const refractionMaterial = new RefractionMaterial({ envMap: envFbo.texture, backfaceMap: backfaceFbo.texture, resolution: [size.width, size.height] })
    const refractionMaterial = new THREE.MeshPhongMaterial({ color:'#ffe500' })
    return [
      //envFbo, 
     // backfaceFbo, 
     // backfaceMaterial, 
      refractionMaterial
    ]
  }, [size])

  // Create random position data
  const dummy = useMemo(() => new THREE.Object3D(), [])
  const diamonds = useMemo(
    () =>
      new Array(80).fill().map((_, i) => ({
        position: [i < 5 ? 0 : width / 2 - Math.random() * width, 40 - Math.random() * 40, i < 5 ? 26 : 10 - Math.random() * 20],
        factor: 0.1 + Math.random(),
        direction: Math.random() < 0.5 ? -0.25 : 0.25,
        rotation: [Math.sin(Math.random()) * Math.PI, Math.sin(Math.random()) * Math.PI, Math.cos(Math.random()) * Math.PI]
      })),
    []
  )

  // Render-loop
  useFrame(() => {
    // Update instanced diamonds
    diamonds.forEach((data, i) => {
      const t = clock.getElapsedTime()
      data.position[1] -= (data.factor / 5) * data.direction
      if (data.direction === 1 ? data.position[1] < -50 : data.position[1] > 50)
        data.position = [i < 5 ? 0 : width / 2 - Math.random() * width, 50 * data.direction, data.position[2]]
      const { position, rotation, factor } = data
    //  dummy.position.set(position[0], position[1], -10)
      dummy.position.set(position[0], position[1], position[2] - 35)
      dummy.rotation.set(rotation[0] + t * factor, rotation[1] + t * factor, rotation[2] + t * factor)
      dummy.scale.set(1 + factor, 1 + factor, 1 + factor)
      dummy.updateMatrix()
      model.current.setMatrixAt(i, dummy.matrix)
    })
    model.current.instanceMatrix.needsUpdate = true
    // Render env to fbo
 
    
    // Render env to screen
 
    // Render cube with refraction material to screen
   // camera.layers.set(0)
  //  model.current.material = refractionMaterial
    gl.render(scene, camera)
  }, 1)

  return (
    <instancedMesh ref={model} args={[gltf.nodes.Star.geometry, null, diamonds.length]}>
      <meshStandardMaterial 
      color={'yellow'} 
    //  clippingPlanes={[clippingPlane]} // Apply the clipping plane to the material
      side={THREE.DoubleSide}
      {...stencil}
      />
      
    </instancedMesh>

  )
}


const MergedGeometryMask = () => {
  // Create the merged geometry (box + sphere)
  const mergedGeometry = useMemo(() => {
    const box = new THREE.BoxGeometry(1, 1, 1);
    const sphere = new THREE.SphereGeometry(0.5, 32, 32);

    // Translate the sphere so it doesn't overlap with the box
    sphere.translate(2, 0, 0);

    // Merge the geometries into a single BufferGeometry
    return mergeBufferGeometries([box, sphere]);
  }, []);

  return (
    <mesh geometry={mergedGeometry}>
      <meshBasicMaterial color="white" />
    </mesh>
  );
};

const InstancedMaskedContent = ({ count }) => {
  const { stencil } = useMask(1); // Apply the mask (ID 1)
  const instancedMeshRef = useMemo(() => new THREE.InstancedMesh(new THREE.BoxGeometry(0.5, 0.5, 0.5), new THREE.MeshBasicMaterial({ color: 'orange' }), count), [count]);

  // Update instance positions
  useMemo(() => {
    for (let i = 0; i < count; i++) {
      const matrix = new THREE.Matrix4();
      matrix.setPosition(i - count / 2, Math.random() * 2, Math.random() * 2);
      instancedMeshRef.setMatrixAt(i, matrix);
    }
    instancedMeshRef.instanceMatrix.needsUpdate = true;
  }, [count, instancedMeshRef]);

  return <primitive ref={instancedMeshRef} {...stencil} />;
};

const Stars = ({
    squareSize,
    previewActive,
    invert,
    gameMode

}) =>{

    const [open, setOpen] = useState(false)
    // We turn this into a spring animation that interpolates between 0 and 1
    const props = useSpring({ open: Number(open) })


    return (
      <>

{/*

<a.pointLight position={[10, 10, 10]} intensity={1.5} color={props.open.to([0, 1], ['#f0f0f0', '#d25578'])} />


<ambientLight intensity={0.4} />
<spotLight
  position={[2, 0, 11]}
  angle={0.6}
  penumbra={1}
  intensity={0.6}
  shadow-mapSize-width={2048}
  shadow-mapSize-height={2048}
  shadow-bias={-0.0001}
/>
*/}
  

  

  <Suspense fallback={null}>
    <>
     {/* <Sizer squareSize={squareSize} /> */}
    
  {(gameMode !== 'intro') &&
     <CircularMask position={[0,0,0]} previewActive={previewActive}/>
}
      <Diamonds width={50} invert={invert}/>
  



    
{/*
       
                <ScreenSizer
                    scale={1} // scale factor
                  >
             
              
              <RoundedBox
                    //position={[0,0.5,1]}
                    //args={[2, 2, 0.01]}
                  
                            args={[610, 610, 0.01]} // Width, height, depth. Default is [1, 1, 1]
                            radius={15} // Radius of the rounded corners. Default is 0.05
                            smoothness={4} // The number of curve segments. Default is 4
                            bevelSegments={4} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
                            creaseAngle={0.4} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
                        >
                            <meshStandardMaterial 
                              color="red" 
                             // transparent 
                             // opacity={0.5} 
                              depthTest={false} 
                              colorWrite={true} 
                              side={THREE.DoubleSide}
                              />
                    </RoundedBox>
               
                  </ScreenSizer>
           */}
                  



     </>

        
   


  </Suspense>

</>

    )}

export default Stars;