import React, { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import {useStoreView} from '../store';

const ViewportSizeUpdater = () => {
  const { viewport } = useThree();
  const setViewportSize = useStoreView((state) => state.setViewportSize);

  useEffect(() => {
    // Update the store whenever the viewport size changes
    setViewportSize(viewport.width, viewport.height);
  }, [viewport, setViewportSize]);

  return null; // This component does not render anything
};

export default ViewportSizeUpdater;