const audioMap = {

  slTimeForStarShades: '/assets/audio/star_shades/SL-Itstimeforstarshades.m4a',
  slStarShades: '/assets/audio/star_shades/SL-Starshades.m4a',
  slBeStar: '/assets/audio/star_shades/SL-Bethestaryouare.m4a',

  slLetsPlay: '/assets/audio/star_shades/SL-Letsplay.m4a',

  jaPickShades: '/assets/audio/star_shades/JA-Clicktopickyourshades.m4a',
  jaChooseGlasses: '/assets/audio/star_shades/JA-ScrolltoChooseYourGlasses.m4a',
  slPickShades: '/assets/audio/star_shades/SL-Pickyourshades.m4a',

  changeBkgd: '/assets/audio/star_shades/Scroll_to_Change_Your_Scene_v2.mp3',
  changeScene: '/assets/audio/star_shades/Click_and_Scroll_to_Change_Your_Scene.mp3',

  jaClickCamera: '/assets/audio/star_shades/JA-Clicktheorangecameratotakeapicture.m4a',

  binkSayBooks: '/assets/audio/star_shades/Bink-SayBooks321Books.m4a',
  slSayBooks: '/assets/audio/star_shades/SL-SayBooks123Books_v3.mp3',

  slBestie: '/assets/audio/star_shades/SL-HeyBestieLikemystarshades.m4a',
  slStar: '/assets/audio/star_shades/SL-Imastarinmystarshades.m4a',
  slAlways: '/assets/audio/star_shades/SL-YourealwaysastaratWayPastBooks.m4a',

  slSend: '/assets/audio/star_shades/SL-Clickthearrowtosendyourpictosomeoneyoulove.m4a',
  slShare: '/assets/audio/star_shades/SL-Clickthearrowtoshareyourpic.m4a',
  slSendBestie: '/assets/audio/star_shades/SL-Sendittoyourbestie.m4a',


  whoosh2: '/assets/audio/whoosh-6316.mp3',
  download: '/assets/audio/download.mp3',
  marimba: '/assets/audio/marimba-bloop-2-188149.mp3',
  pop: '/assets/audio/infographic-pop-5-197872.mp3',
  boop3: '/assets/audio/bloop-3-186532.mp3',
  boop: '/assets/audio/bloop-2-186531.mp3',
  multipop: '/assets/audio/multi-pop-6-188170.mp3',
  click: '/assets/audio/683098__florianreichelt__click.mp3',
  whoosh: 'assets/audio/Woosh-B4-fesliyanstudios.mp3',
  snapshot: 'assets/audio/snapshot.mp3',
  wizard: 'assets/audio/wizard.mp3',
 leila: 'assets/audio/accompanying-background-music-for-holiday-merry-leila-full-version-157683.mp3',
 song3: '/assets/audio/bgm-207019.mp3',
 song2: '/assets/audio/fanny-stories-main-7231.mp3',
 song1: '/assets/audio/kids-134277.mp3',
  // click: '/assets/audio/click-002.m4a',
 // click:'/assets/audio/Mouse_Click_1-fesliyanstudios.com.mp3',

 // sound effects....

 blech: 'assets/audio/SL-Blech.m4a',
 raspberry: 'assets/audio/SL-Rasberry.mp3',
 aww: 'assets/audio/SL-Aww.mp3',
 laugh: 'assets/audio/SL-Laugh.mp3',
 blah: 'assets/audio/JA-Blah.m4a',
 blarg: 'assets/audio/JA-Blarg.m4a',
 lalala: 'assets/audio/JA-Lalala.m4a',
 ohm: 'assets/audio/JA-Ohm.m4a',
 



}

export default audioMap

