import create from 'zustand'
//import niceColorPalette from 'nice-color-palettes/1000'

import produce from 'immer'
import debounce from 'lodash.debounce'
import {mountStoreDevtool} from 'simple-zustand-devtools'

//import { useMediaQuery } from 'react-responsive'
//import { mediaQueries } from './config'

//page1
import BlackTopRimGlasses from './components/glasses/BlackTopRimGlasses'
import BlackMask from './components/glasses/BlackMask'
import RedMask from './components/glasses/RedMask'
//page2
import YellowOvalGlasses from './components/glasses/YellowOvalGlasses'
import HeartGlasses from './components/glasses/HeartGlasses'
import ShadeGlasses from './components/glasses/ShadeGlasses'
//page3
import StarGlasses from './components/glasses/StarGlasses'
import EightBitGlasses from './components/glasses/EightBitGlasses'
import SixtiesGlasses from './components/glasses/SixtiesGlasses'
//page4
import BirthdayGlasses from './components/glasses/BirthdayGlasses'
import BlueGlasses from './components/glasses/BlueGlasses'
//page5
import GoldGlasses from './components/glasses/GoldGlasses'
import AviatorGlasses from './components/glasses/AviatorGlasses'
import ThreeDeeGlasses from './components/glasses/ThreeDeeGlasses'



export const MAX_INDEX = 14
export const INIT_INDEX = 0;//MAX_INDEX / 2 + (Math.random() > 0.5 ? -1 : 1) * Math.round(200 * Math.random())
export const NUM = 14

export const BKGD_MAX_INDEX = 14
export const BKGD_INIT_INDEX = 0;//MAX_INDEX / 2 + (Math.random() > 0.5 ? -1 : 1) * Math.round(200 * Math.random())
export const BKGD_NUM = 9


const rotY = Math.PI/2;
const scale = .3;

export const messages = [
   "",
   "Scroll & Choose Glasses",
   "Change Your Background",
   "Take Your Photo",
   "Download & Share"
 ];




 
 export const backgroundArray = [
  {
    type: 'none', //'image',
   // url: null,//backgroundImageUrls[0],
    url: 'no-image.jpg',
  },
  /*
   {
     type: 'image',
     url: '/assets/backgrounds/mad-neighborhood.jpg',
   },
   */
   {
     type: 'image',
     url: 'star-shades-bkgd-01.jpg',
   },
   {
     type: 'image',
     url: 'star-shades-bkgd-02.jpg',
   },
   {
     type: 'image',
     url: 'star-shades-bkgd-03.jpg',
   },
   {
     type: 'image',
     url: 'star-shades-bkgd-04.jpg',
   },
   {
     type: 'image',
     url: 'star-shades-bkgd-05.jpg',
   },
     {
       type: 'image',
       url: 'star-shades-bkgd-07.jpg',
     },
     {
       type: 'image',
       url: 'star-shades-bkgd-08.jpg',
     },
     {
       type: 'image',
       url: 'star-shades-bkgd-09.jpg',
     },
     {
       type: 'image',
       url: 'star-shades-bkgd-10.jpg',
     },
     {
       type: 'image',
       url: 'star-shades-bkgd-11.jpg',
     },
     {
       type: 'image',
       url: 'lonely-playground.jpg',
     },
     /*
     {
       type: 'image',
       url: '/assets/backgrounds/mad-crowd.jpg',
     },
     {
       type: 'image',
       url: '/assets/backgrounds/mad-house.jpg',
     },
     */
     
 ]

export const glassesArray = [
    
    <StarGlasses  />,
    <BlackMask  />,
    <RedMask  />,
    //page2
    <YellowOvalGlasses  />,
    <HeartGlasses  />,
    <ShadeGlasses  />,
    //page3
    <BlackTopRimGlasses />,
    <EightBitGlasses  />,
    <SixtiesGlasses  />,
    //page4
    <BirthdayGlasses  />,
    <BlueGlasses  />,
    //page5
    <GoldGlasses  />,
    <AviatorGlasses  />,
    <ThreeDeeGlasses  />
];

const persist = (config) => (set, get, api) =>
  config(
    (args) => {
      set(args)
      const { wheelOpen, colors, wheelIndex } = get()
      const s = new URLSearchParams({
        c: btoa(colors.join('')),
        p: wheelIndex
      })
      if (!wheelOpen) {
        window.history.replaceState('', '', `?${s.toString()}`)
      }
    },
    get,
    api
  )


  
const p = INIT_INDEX

const colors = [...new Array(36)].map((_) => {
      return Math.round(Math.random() * 4)
    })

//const wheelIndex = p <= MAX_INDEX ? parseInt(p, 10) : INIT_INDEX

const wheelIndex = INIT_INDEX
const glassesIndex = INIT_INDEX

export const useWheel = create(
  persist((set, get) => ({
    wheelIndex,
    glassesIndex,
    glassWheelActive: false,
    wheelOpen: true,//false,
   // vport: null,
    widthRatio: 1,
    halfTan: 1,
    size: null,
  //  isMobile: useMediaQuery({ query: mediaQueries.mobile }),
  //  isTablet: useMediaQuery({ query: mediaQueries.tablet }),
  //  isDesktop: useMediaQuery({ query: mediaQueries.desktop }),
  //  palette: niceColorPalette[wheelIndex],
    colors,
    glassFollowActive: false,
/*
    setIndex: (index, paletteIndex) =>
      set({
        colors: produce(get().colors, (draft) => {
          draft[index] = paletteIndex
        })
      }),
*/
    //setPalette: (palette) => set({ palette }),
   // setWheelIndex: (wheelIndex) => set({ wheelIndex }),
    setWheelIndex: (wheelIndex) => {
        set((state) => ({
          wheelIndex: wheelIndex,
         // glassesIndex: wheelIndex //(wheelIndex > -1) ? (wheelIndex/NUM) % NUM : (wheelIndex)
        }))
      },
      setGlassesIndex: (num) => {
        set((state) => ({
          glassWheelActive: num,
         // glassesIndex: wheelIndex //(wheelIndex > -1) ? (wheelIndex/NUM) % NUM : (wheelIndex)
        }))
      },
      setGlassWheelActive: (boo) => {
        set((state) => ({
          glassWheelActive: boo,
         // glassesIndex: wheelIndex //(wheelIndex > -1) ? (wheelIndex/NUM) % NUM : (wheelIndex)
        }))
      },
      setHalfTan: (num) => {
        set((state) => ({
          halfTan: num,
        }))
      },
      /*
      setVport: (num) => {
        set((state) => ({
          vport: num,
        }))
      },
      */
      setWidthRatio: (num) => {
        set((state) => ({
          widthRatio: num,
        }))
      },
      setSize: (val) => {
        set((state) => ({
          size: val,
        }))
      },
      setGlassFollowActive: (boo) => {
        set((state) => ({
          glassFollowActive: boo,
        }))
      },
    assignWheelIndex: debounce(() => set((num) => ({ 
        wheelIndex: num,
        glassesIndex: num
    })), 5),
    increaseWheelIndex: debounce(() => set((state) => ({ 
        wheelIndex: state.wheelIndex + 1,
      //  glassesIndex: ((state.wheelIndex % NUM < 0   ) < 0  ) ? 
        glassesIndex: (state.wheelIndex % NUM <= 0   )  ? 
        ((Math.abs(state.wheelIndex + 1) % NUM) === 0) ? 0 :  NUM - (Math.abs(state.wheelIndex + 1) % NUM )
          : 
            Math.abs((state.wheelIndex + 1) % NUM) 
      //  glassesIndex: (wheelIndex <0 ) ?  NUM - (Math.abs( state.wheelIndex + 1) % NUM) : (state.wheelIndex + 1) % NUM
       // glassesIndex: Math.abs((state.wheelIndex + 1) % NUM)
    
    })), 5),
    decreaseWheelIndex: debounce(() => set((state) => ({ 
        wheelIndex: state.wheelIndex - 1,
        glassesIndex: ((state.wheelIndex -1)  < 0 ) ? 
             (((state.wheelIndex -1) % 14) === 0) ? 0 :  NUM - (Math.abs(state.wheelIndex - 1) % NUM) 
            : 
              Math.abs((state.wheelIndex - 1) % NUM) // when decreasing from positive wheelindex
              
      //  glassesIndex: (wheelIndex <0 ) ?  NUM - (Math.abs( state.wheelIndex - 1) % NUM) : (state.wheelIndex - 1) % NUM
       // glassesIndex: Math.abs((state.wheelIndex - 1) % NUM)
    })), 5),
  //  toggleWheel: () => set((state) => ({ wheelOpen: !state.wheelOpen }))
  }))
)


 /*
      setBalloonState(active, msg, audio){
        set((state) => ({
          balloonActive: active,
          balloonMessage: msg,
          balloonAudio: audio,

        }))
      },
      */


export const useStoreView = create((set) => ({
        viewportWidth: 0,
        viewportHeight: 0,
        setViewportSize: (width, height) => set({ viewportWidth: width, viewportHeight: height }),
      }));


export const useStore = create((set, get) => {
  return {

    mainIndex: null,
    bkgdIndex: 0,
    pickedGlass: false,
    pickedScene: false,
    pickedPhoto: false,
    audioState: false,
    balloonActive: false,
    balloonMessage: messages[0],
    balloonAudio: 0,
    stepNum:0,
    domSize: {},
    vport: 1,
    offsetY: 0,
    actions: {
      setDomSize(size) {
        set((state) => ({domSize: size}))
      },
      setOffsetY(num) {
        set((state) => ({offsetY: num}))
      },
      setVport(num) {
        set((state) => ({vport: num}))
      },
      setStepNum(num) {
        set((state) => ({stepNum: num}))
      },
      setBalloonActive(boo) {
        set((state) => ({balloonActive: boo}))
      },
      setBalloonState(active, msg, audio){
        set((state) => ({
          balloonActive: active,
          balloonMessage: msg,
          balloonAudio: audio,

        }))
      },
     
      setMainIndex(num) {
        set((state) => ({mainIndex: num}))
      },
      setBkgdIndex(num) {
        set((state) => ({bkgdIndex: num}))
      },
      setPickedGlass(boo) {
        set((state) => ({pickedGlass: boo}))
      },
      setPickedScene(boo) {
        set((state) => ({pickedScene: boo}))
      },
      setPickedPhoto(boo) {
        set((state) => ({pickedPhoto: boo}))
      },
      setAudioState(boo) {
        set((state) => ({audioState: boo}))
      },
    }

  }


})

if (typeof window !== 'undefined') mountStoreDevtool('Store', useStore)
