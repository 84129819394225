import React, { useEffect, useRef, forwardRef, useState, useLayoutEffect, Suspense   } from 'react'
import { useGLTF, Text, Plane, Html, Box} from "@react-three/drei"
import { useThree, Canvas, useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'



export const FaceHead = forwardRef(({ predictionsRef, debug, model, 
 // xdebug, ydebug, zdebug
   ...props}, ref) => {

{/*
    const scale = 1;
    const scale2 = 15;
    const zpos = -19;
    const zpos2 = -0.6
*/}
/*
    const { scale,scale2, zpos, zposHead } = useControls('Head', {
      scale: {
        value: 1,
        min: 0.1,
        max: 4
      },
      scale2: {
        value: 1,//10,
        min: 1,
        max: 20
      },
      zpos:{
        value: 10,//.5,
        min: -30,
        max: 30
      },
      zposHead:{
        value: 10,//0,
        min: -10,
        max: 10
      },
    })
  
*/
    const { nodes, materials } = useGLTF("head3c.glb")

   // const [xPos, setXPos] = useState(0);
   // const [yPos, setYPos] = useState(0);
   // const [zPos, setZPos] = useState(0);

    const isMobile = useMediaQuery({ query: mediaQueries.mobile })
    const isTablet = useMediaQuery({ query: mediaQueries.tablet })
    const isDesktop = useMediaQuery({ query: mediaQueries.desktop })


    const {viewport} = useThree()
    /*
    const [texture, setTexture] = useState(new THREE.Texture(drawRef.current) );
    const palm = React.useRef();
    const eyeLeft = React.useRef();
    const eyeRight = React.useRef();
    const midEye = React.useRef();
    */
    const axesRef = React.useRef();
/*
    const posRef = React.useRef();
    const textZref = React.useRef();
    const xDebugRef = useRef(0);
  const yDebugRef = useRef(0);
  const zDebugRef = useRef(0);
  */

  const offsetY = (0.2 * viewport.height)/2; 

    const hiderMat = new THREE.MeshPhongMaterial({
     // attach: 'material',
      color: 'red',
  
      opacity: 1,
   //   wireframe: true
    //  alphaMat: true,
     // transparent: true,
  
      colorWrite: false,

     // renderOrder: 1,
    })

    const debugHiderMat = new THREE.MeshPhongMaterial({
      // attach: 'material',
       color: 'red',
   
       opacity: 0.5,//1,
    //   wireframe: true
     //  alphaMat: true,
       transparent: true,
   
   // -> important....   colorWrite: false,
 
      // renderOrder: 1,
     })

    const fakeMat = new THREE.MeshPhongMaterial({
     // attach: 'material',
      color: 'green',
      wireframe: true,
  
      opacity: 1,//0.0,
     // alphaMat: true,
      transparent: true,
  
      //colorWrite: false,
      //renderOrder: 1,
    })

    /*

    useFrame(({ clock }) => {
      const a = clock.getElapsedTime()

    //  console.log('head ref',ref.current.position)

   //   posRef.current = ref.current.position;

   //   xDebugRef.current = ref.current.position.x.toFixed(2)
   //   yDebugRef.current = ref.current.position.y.toFixed(2)
   //   zDebugRef.current = ref.current.position.z.toFixed(2)

      


    //  ref.current.position.z = ref.current.position.z - zposHead

    if (debug){
      setXPos(ref.current.position.x.toFixed(2))
      setYPos(ref.current.position.y.toFixed(2))
      setZPos(ref.current.position.z.toFixed(2))
    }


     
  
    })
  */
  
  
    return (
      <group >
      
      
{/* <axesHelper scale={10} />  */}
        <group 
        ref={ref}
        dispose={null}
        scale={isMobile ? .5 : 1}
 
      //  onPointerDown={() => setAllowControls(false)}
      //  onPointerUp={() => setAllowControls(true)}
      //  onPointerMissed={() => setAllowControls(true)}
       // onPointerMove={handleBrushPointerMove}
       // scale={[2.5,2.5,2.4]}
      //  scale={[2,2,1.25]}
    //    scale={[scale,scale,scale]}
       // position={[0,0,.5]}
       // position={[0,0,zposHead]}
      

        >
           {/*  <axesHelper scale={10} />  */}

          <Box 
             // rotation={[-Math.PI/2,0,0]}
              geometry={nodes.Head_Planar_Mesh__0.geometry} 
              material={(debug) ? debugHiderMat : hiderMat}
              scale={[5.5,5,10]}
              //position-y={.5}
              position-z={-6.5}
              renderOrder={1 }>
              </Box>
   
        {debug &&
        <>
        {/*
              <Text 
              color="black" 
              anchorX="center" 
              anchorY="middle"
              fontSize={.2}
              position-z={1}
              position-y={.25}
              position-x={1.5}
           
              >
                {'x: '+xPos}
            </Text>
            <Text 
              color="black" 
              anchorX="center" 
              anchorY="middle"
              fontSize={.2}
              position-z={1}
              position-y={0}
              position-x={1.5}
              >
                {'y: '+yPos}
            </Text>
             <Text 
              color="black" 
              anchorX="center" 
              anchorY="middle"
              fontSize={.2}
              position-z={1}
              position-y={-.25}
              position-x={1.5}
              >
                {'z: '+zPos}
            </Text>
            */}
           
              

         
                <axesHelper ref={axesRef} />  
                {/*
                <mesh
                rotation={[-Math.PI/2,0,0]}
              //  receiveShadow 
              //  castShadow 
                geometry={nodes.Head_Planar_Mesh__0.geometry} 
           //     material={(debug) ? debugHiderMat : hiderMat}
                material={fakeMat}
                scale={[40, 30, 30]}
               // scale={[1,1,1]}
               // scale={[scale2, scale2, scale2 - 2]}

              //  renderOrder={1 }
              //  position={[0,0,zpos2]}
                
              >
        

     
                </mesh>
                */}
                
     
                <Plane args={[10, 10]} material={fakeMat}/>
              </>
          }
         

      </group>
        
      </group>
    
    );
  
  });
  