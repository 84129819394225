import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useSpring, useTransition, animated, config, easings} from '@react-spring/web'
import { useWheel, useStore } from '../store'

import useTimeout from '../hooks/useTimeout';
import audioMap from '../resources/audio';
import useSound from 'use-sound';


const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    
    return <div className="timer emoji" >👌</div>;
  }

  return (
    <div className="timer">
      <div className="value">{4 - remainingTime}</div>
    </div>
  );
};

const SnapButton = ({exportAsPicture, gameMode,setMenuMode,setKill, kill, timerTrigger }) => {
//function Countdown() {
  const [key, setKey] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [active, setActive] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const setGlassWheelActive = useWheel((s) => s.setGlassWheelActive)

  const {setAudioState} = useStore((state) => state.actions)
  const audioState = useStore((state) => state.audioState)

  const {setPickedPhoto} = useStore((state) => state.actions)

  const {opacity, ...colorProps} = useSpring(
    {
     // to: [{
    //    scale: (previewActive) ? 1.0 : 0.1},
    //   { top: (previewActive) ?  0 : (window.innerHeight) / 5   }],

      to: {
        backgroundColor: active ?  'orange'  : 'grey',
        opacity: trigger ? 0 : active ? 1 : 0.5
      },
     // delay: (show) ? (hover) ? 0 : 600 : 0,
     // duration: 500,//(snapActive) ? 200 : 0,
      config: config.gentle,
     // config:  easings.easeInOutQuart,
      from:{
          backgroundColor: 'grey',
          opacity: 0.5
      },
    /*  
      config: {
        duration: 2000,
        easing: easings.easeInOutQuart,
      },
      */
      onRest() {
       
      },
    }
  )


  const [playBoop3SFX] = useSound(audioMap.boop3, {
    volume:  (audioState) ? 1 : 0,
   // playbackRate: playbackRate
   // interrupt: true,
  });

  const [playSlSayBooks] = useSound(audioMap.slSayBooks, {
    volume: (audioState) ? 1 : 0,
    interrupt: true
  });

  useEffect(() => {
    let timer;
    if (gameMode==='freeplay'){
        setActive(true)
        setInProgress(false)
      //  alert('snapbtn freeplay')
    }else if (gameMode==='polaroid'){
        
        timer = setTimeout(() => {

          setTrigger(false)
         // console.log("again");
        }, 1000);
    }
    return () => clearTimeout(timer);
  }, [gameMode])


  const [timeoutTrigger] = useTimeout(() => {

    setKey(prevKey => prevKey + 1)
    setTrigger(true)
    setGlassWheelActive(false)
    
  }, 1500);

  const triggerCountdown = () => {


    setKill(!kill)
    playSlSayBooks();
    timeoutTrigger();
    /*
    setKey(prevKey => prevKey + 1)
    setTrigger(true)
    setGlassWheelActive(false)
    */
  }



  return (
    <animated.button 
        className="snapButton" 
       // onClick={exportAsPicture}
        onClick={() => {
          
          if(!inProgress){

              setInProgress(true);
              playBoop3SFX()
              setMenuMode('neutral')
              if (active) {
                setPickedPhoto(true);
                triggerCountdown();
              }
            }
         }
        }
        style={colorProps}
        >
        <animated.div className="snapIcon" style={{opacity: opacity}}></animated.div>

      <div className="timer-wrapper">
        <CountdownCircleTimer
          key={key}
          isPlaying={trigger ? true : false}
          duration={3}
          strokeWidth={4}
          trailStrokeWidth={4}
         // trailColor={"#004777"}
          size={100}
          trailColor={"#ff6300"}
          colors={["#ffffff"]}
         // colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
         // colorsTime={[3,2,1,0]}
       
        
          onComplete={exportAsPicture}
        >
          {trigger ? renderTime : null}
        </CountdownCircleTimer>
      </div>
      {/*
      <div className="button-wrapper">
        <button onClick={() => setKey(prevKey => prevKey + 1)}>
          Restart Timer
        </button>
      </div>
      */}
    </animated.button>
  );
}

export default SnapButton;