
import React, { useEffect, useRef, forwardRef, useMemo, useState, useLayoutEffect, Suspense, Sphere   } from 'react'
import { useThree, Canvas, useFrame } from '@react-three/fiber'
//import { EffectComposer, SSAO, SMAA, Selection, Outline } from "@react-three/postprocessing"
import { ScrollControls, Scroll} from "@react-three/drei"
import { useSpring, useSprings, useChain, useSpringRef, config, easings } from '@react-spring/core'
import * as THREE from 'three'

//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';



import { FaceHead } from "../components/FaceHead";



import ScrollGlasses from '../components/ScrollGlasses'
import ScrollScenes from '../components/ScrollScenes'
import FaceGlasses from '../components/FaceGlasses'

import ViewportSizeUpdater from '../components/ViewportSizeUpdater';
//import FakeGlowMaterial from '../components/FakeGlowMaterial'

import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'

import {useStore } from '../store'

import { vrmRef } from "../constant";




/*
function Effects() {
  const { size } = useThree()
  useFrame((state, delta) => {
    // easing.damp3(state.camera.position, [state.pointer.x, 1 + state.pointer.y / 2, 8 + Math.atan(state.pointer.x * 2)], 0.3, delta)
    // state.camera.lookAt(state.camera.position.x * 0.9, 0, -4)
  })
  return (
    <EffectComposer stencilBuffer disableNormalPass autoClear={false} multisampling={4}>
      <Outline visibleEdgeColor="white" hiddenEdgeColor="white" blur width={size.width * 1.25} edgeStrength={10} />
    </EffectComposer>
  )
}
*/

const ThreeActiveArea= ({
   // vrmRef, 
    debug,
    drawCanvasRef,
   textureRef,
    setAllowControls,
   // gameState,
    glassesActive,
    gameMode,
    clickedItem,
  //  chosenGlasses,
  //  setChosenGlasses,
    squareSize,
  //  isolateGlass,
    menuMode,
    setMenuMode,
    playWhooshSFX,
    playMultipopSFX,
    playBoopSFX


}) =>{

    const [open, setOpen] = useState(false)
    // We turn this into a spring animation that interpolates between 0 and 1
    const props = useSpring({ open: Number(open) })

    const isMobile = useMediaQuery({ query: mediaQueries.mobile })
const isTablet = useMediaQuery({ query: mediaQueries.tablet })
const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

   // const {width} = useThree((state) => state.viewport)

   

  //  const {scene, camera} = useThree()


  const column1 = -6;
  const column2 = -3;
  const column3 = 3;
  const column4 = 6;
  const column5 = 8;


  


  /*
    const getViewSize = () => {

      const fovInRadians = (camera.fov * Math.PI) / 180;
      const height = Math.abs(
        camera.position.z * Math.tan(fovInRadians / 2) * 2
      );
  
      return { width: height *camera.aspect, height };
    }
    */

    return (
<>


<ViewportSizeUpdater />


{/*
<gridHelper /> 
<Box 
  position={[0, 0, 0]} color="#000000" 
  scale={[.5,.5,.5]}/>
*/}





<group position={[0, 0, 0]} >


  <Suspense fallback={null}>
  
 
  
  
          <FaceHead 
            //  predictionsRef={predictionsRef} 
           //   drawRef={drawCanvasRef}
           //   textureRef={textureRef}
            //  setAllowControls={setAllowControls}
              ref={vrmRef}
              model={true}
            //  xdebug={xDebugRef.current}
            //  ydebug={yDebugRef.current}
            //  zdebug={zDebugRef.current}
             debug={debug}
              
          />

          <FaceGlasses 
            follower={vrmRef.current} 
         //   chosenGlasses={chosenGlasses}
         //   setChosenGlasses={setChosenGlasses}
         //   clickedItem={clickedItem}
         //   glassesActive={glassesActive}
          //  debug={debug}

  
            />

   




{(menuMode === 'glasses') ?
        <ScrollControls
           // infinite
            horizontal
            
          //  damping={isMobile ? 20 : 10}
            damping={0.3}
            distance={0.5}

            pages={
              isMobile? 14 :  window.innerWidth/2 * 15 /  window.innerWidth
              // isMobile ? 14 : 7
            }

          //  distance={1}
            onPointerMissed={() => setMenuMode('neutral') }
          >
            <Scroll>

            <ScrollGlasses
                         follower={vrmRef.current} 
                         objType={'glasses'}
                       //  chosenGlasses={chosenGlasses}
                       //  setChosenGlasses={setChosenGlasses}
                         clickedItem={clickedItem}
                         gameMode={gameMode}
                       //  previewActive={glassesActive}
                         debug={debug}
                         menuMode={menuMode}
                         setMenuMode={setMenuMode}
                         playWhooshSFX={playWhooshSFX}
                         playMultipopSFX={playMultipopSFX}
                         playBoopSFX={playBoopSFX}
                         
                         
              />
             


          {/*
                    <ScrollObjects
                         follower={vrmRef.current} 
                         objType={'glasses'}
                       //  chosenGlasses={chosenGlasses}
                       //  setChosenGlasses={setChosenGlasses}
                         clickedItem={clickedItem}
                         gameMode={gameMode}
                       //  previewActive={glassesActive}
                         debug={debug}
                         menuMode={menuMode}
                         setMenuMode={setMenuMode}
                         playWhooshSFX={playWhooshSFX}
                         playMultipopSFX={playMultipopSFX}
                         playBoopSFX={playBoopSFX}
                         
                         
              />
              */}
              
            </Scroll>
          </ScrollControls>
           : <></>
}

{(menuMode === 'scenes') ?
        <ScrollControls
           // infinite
            horizontal
            damping={0.3}
            distance={0.5}
           // distance={10}
           // damping={10}
         //   damping={isMobile ? 20 : 10}
            pages={
             // isMobile ? 9 : 5
              isMobile? 9 :  window.innerWidth/2 * 9 /  window.innerWidth
          
            }

          //  distance={1}
            onPointerMissed={() => setMenuMode('neutral') }
          >
            <Scroll>


          <Suspense>
                    <ScrollScenes
                         follower={vrmRef.current} 
                         objType={'scenes'}
                       //  chosenGlasses={chosenGlasses}
                       //  setChosenGlasses={setChosenGlasses}
                         clickedItem={clickedItem}
                         gameMode={gameMode}
                       //  previewActive={glassesActive}
                         debug={debug}
                         menuMode={menuMode}
                         setMenuMode={setMenuMode}
                         playWhooshSFX={playWhooshSFX}
                         playMultipopSFX={playMultipopSFX}
                         playBoopSFX={playBoopSFX}
                         
              />
              </Suspense>
              
            </Scroll>
          </ScrollControls>
          :
          <></>
}


          {/*
  <InteractiveModel 
    follower={vrmRef.current} 
    chosenGlasses={chosenGlasses}
    setChosenGlasses={setChosenGlasses}
    clickedItem={clickedItem}
    
   // restPos={[-2.8, 0, -9]}
    gameMode={gameMode}
    previewActive={glassesActive}
    delay={200}
    hidePos={[column1, -15, -26]}
    restPos={[column1, 5.5, -28]}
    debug={debug}
    >
   
    <BlackTopRimGlasses
     // key="hearts"
    //  scale={[1.85, 1.85, 2.75]}
    //  position={[0, 0.15, -0.1]}
     // position={[0, .3, 1.1]}
      renderOrder={2}
    />

  </InteractiveModel>
      
  <InteractiveModel
    follower={vrmRef.current}
    chosenGlasses={chosenGlasses}
        setChosenGlasses={setChosenGlasses}
    clickedItem={clickedItem}
    gameMode={gameMode}
    previewActive={glassesActive}
    hidePos={[column1, -13, -24]}
    restPos={[column1, .5, -25]}
    delay={500}
    debug={debug}
  >
    <BlackMask
     // scale={[1.6, 1.6, 1.6]}
     // position={[0, .8, 1.3]}
     // rotation={[-0.1, 0, 0]}
      renderOrder={2}
    />
  </InteractiveModel>

  

 
  
  <InteractiveModel
    follower={vrmRef.current}
    chosenGlasses={chosenGlasses}
    setChosenGlasses={setChosenGlasses}
    clickedItem={clickedItem}
    gameMode={gameMode}
  //  restPos={[-2.5, -1.7, -11]}
  previewActive={glassesActive}
  hidePos={[column1, -10, -25]}
    restPos={[column1, -3.7, -27]}
    delay={700}
    debug={debug}
  >
    <RedMask
     // key="threedee"
    //  scale={[2.1, 2, 2]}
    //  position={[0, 0.125, -0.1]}
    //  position={[0, .3, 1.2]}
      renderOrder={2}
    />
  </InteractiveModel>



  <InteractiveModel 
  follower={vrmRef.current} 
  chosenGlasses={chosenGlasses}
        setChosenGlasses={setChosenGlasses}
  clickedItem={clickedItem}
  gameMode={gameMode}
 // restPos={[2.5, 1.2, -10]}
  previewActive={glassesActive}
  hidePos={[column2, -10, -24]}
  restPos={[column2, 3, -26]}
  delay={100}
  debug={debug}
 // restPos={(previewActive)?  [7, 3, -26] : [7, -10, -24]}
  >
    <YellowOvalGlasses
     // key="sports"
      //scale={[0.7, 0.65, 0.7]}
     // scale={[2, 2, 2.2]}
    //  position={[-0.05, 0.175, -0.1]}
    //  position={[0, .6, 1.2]}
     // rotation={[0, -0.1, 0]}
      renderOrder={2}
    />
  </InteractiveModel>

  <InteractiveModel 
  follower={vrmRef.current} 
  chosenGlasses={chosenGlasses}
        setChosenGlasses={setChosenGlasses}
  clickedItem={clickedItem}
  gameMode={gameMode}
 // restPos={[3, 0, -10]}
  previewActive={glassesActive}
  hidePos={[column2, -10, -22]}
  restPos={[column2, 0, -24]}
  delay={300}
  debug={debug}
//  restPos={(previewActive)?  [9, 0, -24] : [9, -10, -22]}
  
  >
    <HeartGlasses
     // key="purple"
     // scale={[0.7, 0.7, 0.7]}
     // scale={[2, 2, 2]}
   //   position={[0, 0.125, -0.1]}
    //  position={[0, .3, 1.2]}
      renderOrder={2}
    />
  </InteractiveModel>

  <InteractiveModel
    follower={vrmRef.current}
    chosenGlasses={chosenGlasses}
        setChosenGlasses={setChosenGlasses}
    clickedItem={clickedItem}
    gameMode={gameMode}
    previewActive={glassesActive}
    hidePos={[column2, -12, -24]}
    restPos={[column2, -3.6, -26]}
    delay={600}
    debug={debug}
  >


    <ShadeGlasses
    //  key={"pineapple"}
    //  scale={[2, 2, 2]}
    //  position={[0, .3, 1.1]}
      renderOrder={2}
    />

  
  </InteractiveModel>

  <InteractiveModel 
  follower={vrmRef.current} 
  chosenGlasses={chosenGlasses}
        setChosenGlasses={setChosenGlasses}
  clickedItem={clickedItem}
  gameMode={gameMode}
 // restPos={[3, 0, -10]}
  previewActive={glassesActive}
  hidePos={[column2-1.5, -10, -22]}
  restPos={[column2-1.5, 0, -24]}
  delay={300}
  debug={debug}
//  restPos={(previewActive)?  [9, 0, -24] : [9, -10, -22]}
  
  >
    <BirthdayGlasses
     // key="purple"
     // scale={[0.7, 0.7, 0.7]}
     // scale={[2, 2, 2]}
   //   position={[0, 0.125, -0.1]}
    //  position={[0, .3, 1.2]}
      renderOrder={2}
    />
  </InteractiveModel>



  <InteractiveModel
    follower={vrmRef.current}
    chosenGlasses={chosenGlasses}
        setChosenGlasses={setChosenGlasses}
    clickedItem={clickedItem}
    gameMode={gameMode}
    previewActive={glassesActive}
    hidePos={[column3, -12, -24]}
    restPos={[column3, 3, -26]}
    delay={600}
    debug={debug}
  >


    <StarGlasses
    //  key={"pineapple"}
    //  scale={[2, 2, 2]}
    //  position={[0, .3, 1.1]}
      renderOrder={2}
    />

  
  </InteractiveModel>
  <InteractiveModel
    follower={vrmRef.current}
    chosenGlasses={chosenGlasses}
        setChosenGlasses={setChosenGlasses}
    clickedItem={clickedItem}
    gameMode={gameMode}
    previewActive={glassesActive}
    hidePos={[column3, -12, -24]}
    restPos={[column3, 0, -26]}
    delay={600}
    debug={debug}
  >


    <EightBitGlasses
    //  key={"pineapple"}
    //  scale={[2, 2, 2]}
    //  position={[0, .3, 1.1]}
      renderOrder={2}
    />

  
  </InteractiveModel>
  <InteractiveModel
    follower={vrmRef.current}
    chosenGlasses={chosenGlasses}
        setChosenGlasses={setChosenGlasses}
    clickedItem={clickedItem}
    gameMode={gameMode}
    previewActive={glassesActive}
    hidePos={[column3, -12, -24]}
    restPos={[column3, -3.6, -26]}
    delay={600}
    debug={debug}
  >


    <SixtiesGlasses
    //  key={"pineapple"}
    //  scale={[2, 2, 2]}
    //  position={[0, .3, 1.1]}
      renderOrder={2}
    />

  
  </InteractiveModel>


  <InteractiveModel
    follower={vrmRef.current}
    chosenGlasses={chosenGlasses}
        setChosenGlasses={setChosenGlasses}
    clickedItem={clickedItem}
    gameMode={gameMode}
    previewActive={glassesActive}
    hidePos={[column3-1.5, -12, -24]}
    restPos={[column3-1.5, 0, -26]}
    delay={600}
    debug={debug}
  >


    <BlueGlasses
    //  key={"pineapple"}
    //  scale={[2, 2, 2]}
    //  position={[0, .3, 1.1]}
      renderOrder={2}
    />

  
  </InteractiveModel>



  <InteractiveModel
    follower={vrmRef.current}
    chosenGlasses={chosenGlasses}
        setChosenGlasses={setChosenGlasses}
    clickedItem={clickedItem}
    gameMode={gameMode}
    previewActive={glassesActive}
    hidePos={[column4, -12, -24]}
    restPos={[column4, 3, -26]}
    delay={600}
    debug={debug}
  >


    <GoldGlasses
    //  key={"pineapple"}
    //  scale={[2, 2, 2]}
    //  position={[0, .3, 1.1]}
      renderOrder={2}
    />

  
  </InteractiveModel>
  <InteractiveModel
    follower={vrmRef.current}
    chosenGlasses={chosenGlasses}
        setChosenGlasses={setChosenGlasses}
    clickedItem={clickedItem}
    gameMode={gameMode}
    previewActive={glassesActive}
    hidePos={[column4, -12, -24]}
    restPos={[column4, 0, -26]}
    delay={600}
    debug={debug}
  >


    <AviatorGlasses
    //  key={"pineapple"}
    //  scale={[2, 2, 2]}
    //  position={[0, .3, 1.1]}
      renderOrder={2}
    />

  
  </InteractiveModel>
  <InteractiveModel
    follower={vrmRef.current}
    chosenGlasses={chosenGlasses}
        setChosenGlasses={setChosenGlasses}
    clickedItem={clickedItem}
    gameMode={gameMode}
    previewActive={glassesActive}
    hidePos={[column4, -12, -24]}
    restPos={[column4, -3.6, -26]}
    delay={600}
    debug={debug}
  >


    <ThreeDeeGlasses
    //  key={"pineapple"}
    //  scale={[2, 2, 2]}
    //  position={[0, .3, 1.1]}
      renderOrder={2}
    />

  
  </InteractiveModel>
          */}

  </Suspense>




  

</group>

</>


    )}

export default ThreeActiveArea;