/*
import { useRef, useEffect, useCallback } from 'react';

export default function useTimeout2(callback, delay) {
  const savedCallback = useRef();
  const timeoutId = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutId.current = setTimeout(() => {
      savedCallback.current();
    }, delay);
  }, [delay]);

  const clear = useCallback(() => {
    clearTimeout(timeoutId.current);
  }, []);

  useEffect(() => {
    if (delay !== null) {
      set();
      return clear;
    }
  }, [delay, set, clear]);

  return { set, clear };
}
*/
/*
import { useRef, useEffect, useCallback, useMemo } from 'react';

function useTimeout2(callback, delay) {
  const savedCallback = useRef();
  const timeoutId = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutId.current = setTimeout(() => {
      savedCallback.current();
    }, delay);
  }, [delay]);

  const memoizedCallback = useCallback(
    (args) => {
      if (timeoutId.current) {
        window.clearTimeout(timeoutId.current);
      }
      timeoutId.current = window.setTimeout(() => {
        timeoutId.current = null;
        savedCallback.current?.(args);
      }, delay);
    },
    [delay, timeoutId, callbackRef]
  );

  const clear = useCallback(() => {
    clearTimeout(timeoutId.current);
  }, []);

  useEffect(() => {
    if (delay !== null) {
      set();
      return clear;
    }
  }, [delay, set, clear]);

  //return { set, clear };
  return useMemo(() => [memoizedCallback], [memoizedCallback]);
}

export default useTimeout2;

*/

import { useRef, useEffect, useCallback } from 'react';

function useTimeout2(callback, delay) {
  const savedCallback = useRef();
  const timeoutId = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      savedCallback.current();
    }, delay);
  }, [delay]);

  const clear = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, []);

  useEffect(() => {
    if (delay !== null) {
      set();
      return clear;
    }
  }, [delay, set, clear]);

  return { set, clear };
}

export default useTimeout2;