// @ts-nocheck
import { useGLTF, useTexture, Plane, Box, Svg } from '@react-three/drei'
import React, { useRef } from 'react'
import * as THREE from 'three'

const texture1 = `${process.env.PUBLIC_URL}/assets/images/glasses/page4a-frame.png`;
const svg2 = `${process.env.PUBLIC_URL}/assets/images/glasses/page4a-arm.svg`;


export default function Model({...props}) {

 
  const group = useRef()
  const bodyTex  = useTexture(texture1)

  return (
    <group ref={group} {...props} dispose={null} position-y={.4} scale={.9}>

      <Svg src={svg2} scale={.001} position={[-1.35,-0.15,-1.77]} rotation={[0, -Math.PI/2, 0]} />
      <Svg src={svg2} scale={.001} position={[1.29,-0.15,-1.77]} rotation={[0, -Math.PI/2, 0]} />


<Plane args={[3.75, 2.35]} 
      position={[0,0.3,0]}
      >
         <meshBasicMaterial  map={bodyTex} side={THREE.DoubleSide} alphaTest={0.1}
         transparent 
         />
      </Plane>
      {/*
      <Plane args={[2.12, .8]} 
      position={[-0.9,-0.25,-0.6]} 
      rotation={[0, -Math.PI/2, 0]}>
         <meshBasicMaterial transparent map={armTex} side={THREE.DoubleSide} alphaTest={0.1} />
      </Plane>
      <Plane args={[2.12, .8]} 
      position={[0.9,-0.25,-0.6]} 
      rotation={[0, -Math.PI/2, 0]}>
         <meshBasicMaterial transparent map={armTex} side={THREE.DoubleSide} alphaTest={0.1} />
      </Plane>
  */}
     
     

    </group>
  )
}

