import React, { useState, useEffect } from 'react'
import { useTransition,useSprings, animated, config, easings} from '@react-spring/web'
//import styles from './styles.module.css'
import { useStore, backgroundArray } from '../store'

const BackgroundPanel = ({counter, gameState}) =>{
//export default function BackgroundPanel({bkgd}) {
  const bkgdIndex = useStore((state) => state.bkgdIndex)
  const [index, set] = useState(0)
  const [show, setShow] = useState(false)

  const images = [
    null,
    process.env.PUBLIC_URL+'/assets/backgrounds/'+ backgroundArray[1].url,
    process.env.PUBLIC_URL+'/assets/backgrounds/'+ backgroundArray[2].url,
    process.env.PUBLIC_URL+'/assets/backgrounds/'+ backgroundArray[3].url,
    process.env.PUBLIC_URL+'/assets/backgrounds/'+ backgroundArray[4].url,
    process.env.PUBLIC_URL+'/assets/backgrounds/'+ backgroundArray[5].url,
    process.env.PUBLIC_URL+'/assets/backgrounds/'+ backgroundArray[6].url,
    process.env.PUBLIC_URL+'/assets/backgrounds/'+ backgroundArray[7].url,
    process.env.PUBLIC_URL+'/assets/backgrounds/'+ backgroundArray[8].url,
  ];

  const springs = useSprings(
    images.length,
    images.map((_, i) => ({
      opacity: i === index ? 1 : 0,
      transform: i === index ? 'scale(1.1)' : 'scale(1.3)',
     // config: { tension: 220, friction: 30 },
      config: {
        duration: 500,
        easing: easings.easeInOutBack,
      },
    }))
  );
 
 // const [backImg, setBackImg] = useState(bkgd)
  const transitions = useTransition(index, {
    key: index,
    from: { 
        opacity: 1,
        scale: 1.2
    },
    enter: { 
        opacity: 1,
        scale:1.02
     },
    leave: { 
        opacity: 0,
        scale: 1.05
     },
   // config: { duration: 600 },
   config: {
       duration:1500,
      easing: easings.easeInOutBack,
   },
 //   config: config.gentle,
   // onRest: (_a, _b, item) => {
     // if (index === item) {
       //  set(state => (state + 1) % slides.length)
     // }
  //  },
    exitBeforeEnter: true,
  })





  useEffect(() => {
  console.log('bkgdIndex'+ bkgdIndex)
   set(bkgdIndex)
 }, [bkgdIndex])
 
/*
 useEffect(() => {
    if(gameState==='intro'){
        setShow(false)
    }else{
        setShow(true)
    }
 }, [gameState])
*/
/*
  useEffect(() => {
    console.log('bkgd', bkgd)
    setBackImg(bkgd)
    }, [bkgd])
*/



  return (
    <div className="flex fill center background round-edge" 
    style={{
     // background: (counter===0) ? 'transparent' : 'white'
    }}>
{/*
        <div
          className="bg"
          style={{
           backgroundImage: null
          }}
        />
       
         <animated.div
          className="bg"
          style={{
           backgroundImage: `url(${process.env.PUBLIC_URL}/assets/backgrounds/${backgroundArray[1].url})`
          }}
        />
         <animated.div
          className="bg"
          style={{
           backgroundImage: `url(${process.env.PUBLIC_URL}/assets/backgrounds/${backgroundArray[2].url})`
          }}
        />
         <animated.div
          className="bg"
          style={{
           backgroundImage: `url(${process.env.PUBLIC_URL}/assets/backgrounds/${backgroundArray[3].url})`
          }}
        />
         */}
      {springs.map((styles, i) => (
        <animated.img
          key={i}
          src={images[i]}
          style={{
            ...styles,
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}
        />
      ))}

    </div>
  )
/*
  return (
    <div className="flex fill center background round-edge" 
    style={{
     // background: (counter===0) ? 'transparent' : 'white'
    }}>
      {transitions((style, i) => (
        <animated.div
          className="bg"
          style={{
            ...style,
           // backgroundImage: 'url('+bkgd+')',
           // backgroundImage: 'url('+backImg+')',//`url(https://images.unsplash.com/${slides[i]}?w=1920&q=80&auto=format&fit=crop)`,
           // backgroundImage: `url(https://images.unsplash.com/${slides[i]}?w=1920&q=80&auto=format&fit=crop)`,
           //backgroundImage:`url(${backgroundArray[bkgdIndex].url})`
           backgroundImage: (bkgdIndex===0) ? null : `url(${process.env.PUBLIC_URL}/assets/backgrounds/${backgroundArray[index].url})`
          }}
        />
      ))}

    </div>
  )
    */
}

//export default React.memo(BackgroundPanel);
export default BackgroundPanel;
