// @ts-nocheck
import { useGLTF, useTexture, Plane, Box, Svg } from '@react-three/drei'
import React, { useRef } from 'react'
import * as THREE from 'three'

const texture1 = `${process.env.PUBLIC_URL}/assets/images/glasses/page2c-frame.png`;
const svg2 = `${process.env.PUBLIC_URL}/assets/images/glasses/page2c-arm.svg`;


export default function Model(props) {
  const group = useRef()
  const bodyTex  = useTexture(texture1)

  return (
    <group ref={group} {...props} dispose={null} position-y={-0.06} scale={0.9}>

      <Svg src={svg2} scale={.001} position={[-1.4,0.55,-1.74]} rotation={[0, -Math.PI/2, 0]}/>
      <Svg src={svg2} scale={.001} position={[1.4,0.55,-1.74]} rotation={[0, -Math.PI/2, 0]}/>


      <Plane args={[3.275, 1.21]} 
      position={[0,0.2,0]}
      >
         <meshBasicMaterial  map={bodyTex} side={THREE.DoubleSide} alphaTest={0.1}
         transparent 
         />
      </Plane>
      {/*
      <Plane args={[2, 0.63]} 
      position={[-1.38,0.175,-0.975]} 
      rotation={[0, -Math.PI/2, 0]}>
         <meshBasicMaterial transparent map={armTex} side={THREE.DoubleSide} alphaTest={0.1} />
      </Plane>
      <Plane args={[2, 0.63]}  
      position={[1.38,0.175,-0.975]} 
      rotation={[0, -Math.PI/2, 0]}>
         <meshBasicMaterial transparent map={armTex} side={THREE.DoubleSide} alphaTest={0.1} />
      </Plane>
  */}
     

    </group>
  )
}

