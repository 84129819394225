import React, { useEffect, useRef, useState } from 'react'
import { useSpring, config, easings } from '@react-spring/core'
import { a as web } from '@react-spring/web'
import * as THREE from 'three'

import Webcam from "react-webcam";

import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from './config'

import html2canvas from 'html2canvas'
import audioMap from './resources/audio';
import useTimeout from './hooks/useTimeout';

import { HiSpeakerWave, HiSpeakerXMark  } from "react-icons/hi2";
import useSound from 'use-sound';

import { useWheel, useStore, useStoreView } from './store'
import * as Kalidokit from "kalidokit";

import {
    FaceMesh,
    FACEMESH_LEFT_EYE,
  } from '@mediapipe/face_mesh'

 import {SelfieSegmentation} from '@mediapipe/selfie_segmentation';
import * as cam from '@mediapipe/camera_utils';


import SpeechBubble from './components/SpeechBubble';
import TitleCard from './components/TitleCard';
import SelfieControls from './components/SelfieControls';
import PrintControl from './components/PrintControl';
import BackgroundPanel from './components/BackgroundPanel';
import SnapshotPanel from './components/SnapshotPanel';
import LogoBug from './components/LogoBug';

import ThreeSpace from './components/ThreeSpace'
import SequentialActions from './components/SequentialActions';

import { vrmRef } from "./constant";
import ActionControls from './components/ActionControls';

import ReactGA from 'react-ga4';

import "./styles.scss";


function shadeColor(color, percent) {

  var R = parseInt(color.substring(1,3),16);
  var G = parseInt(color.substring(3,5),16);
  var B = parseInt(color.substring(5,7),16);

  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = (R<255)?R:255;  
  G = (G<255)?G:255;  
  B = (B<255)?B:255;  

  var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

  return "#"+RR+GG+BB;
}


export default function App() {

  


  const [squareSize, setSquareSize] = useState(0);
  const [kill, setKill] = useState(false);

  const [webcamPermission, setWebcamPermission] = useState(false);
  const [webcamActive, setWebcamActive] = useState(false);

  const [titleActive, setTitleActive] = useState(false);

  // webcam ready
  const [videoActive, setVideoActive] = useState(false);
  // tracking ready
  const [trackingActive, setTrackingActive] = useState(false);

  const [framedOutput, setFramedOutput] = useState(false);

 // const [balloonActive, setBalloonActive] = useState(false);

 const [selectedBackground, setSelectedBackground] = useState(null);
 const [camera, setCamera] = useState(null);

 const {vport} = useStore((state) => state.vport)
 const {domSize} = useStore((state) => state.domSize)
 const {offsetY} = useStore((state) => state.offsetY)

 const viewportHeight = useStoreView((state) => state.viewportHeight);
 const viewportWidth = useStoreView((state) => state.viewportWidth);
 const vportRef = useRef(0);
// vportRef.current = vport;
 //console.log('start vportRef.current', vportRef.current)

  //const {balloonActive} = useStore((state) => state.balloonActive)
  const {balloonMessage} = useStore((state) => state.balloonMessage)
  const {balloonAudio} = useStore((state) => state.balloonAudio)
  const {setBalloonState} = useStore((state) => state.actions)

 // const {vrmRef} = useStore((state) => state.vrmRef)


  const [balloonCounter, setBalloonCounter] = useState(0);

  //segementation ready
  const [segmentActive, setSegmentActive] = useState(false);
  const [segmentDisabled, setSegmentDisabled] = useState(false);
  // background component active
  const  [bkgdActive, setBkgdActive] = useState(false);
  // 3d glasses active
  const  [glassesActive, setGlassesActive] = useState(false);

  const [previewActive, setPreviewActive] = useState(false);
  // white flash on snapshot
  const [snapActive, setSnapActive] = useState(false);
  const [isolateGlass, setIsolateGlass] = useState(false);

  const [ready, setReady] = React.useState(false);
  const [camImg, setCamImg] = useState(null);
  const [overlayImg, setOverlayImg] = useState(null);
  const [polaroidImg, setPolaroidImg] = useState('');

  const [counter, setCounter] = useState(0)
  const [itemCounter, setItemCounter] = useState(0)
  const [hover, setHover] = useState(false)
  const [clickedScene, setClickedScene] = useState(false)


  const [gameState, setGameState] = useState('intro');
  const [menuMode, setMenuMode] = useState('neutral');

  const menuRef = useRef(menuMode)

  const [debug, setDebug] = useState(false);

  const [chosenGlasses, setChosenGlasses] = useState(null);


  const {setMainIndex, setBkgdIndex, setAudioState} = useStore((state) => state.actions)
  const audioState = useStore((state) => state.audioState)
  const bkgdIndex = useStore((state) => state.bkgdIndex)

  const bkgdRef = useRef(null)
  bkgdRef.current = bkgdIndex;



  const cameraModeRef = useRef(1)
  const [webcamOn, setWebcamOn] = useState(true);
  const webcamRef = useRef(1)

  const [siloActivated, setSiloActivated] = useState(false);
  const [siloOn, setSiloOn] = useState(false);
  const siloRef = useRef(0)

//intro, instGlasses, instScene, instPhoto, freeplay, polaroid

const isMobile = useMediaQuery({ query: mediaQueries.mobile })
const isTablet = useMediaQuery({ query: mediaQueries.tablet })
const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

//const vport = useWheel((s) => s.vport)
//const size = useWheel((s) => s.size)
const widthRatio = useWheel((s) => s.widthRatio)
const halfTan = useWheel((s) => s.halfTan)


const [playbackRate, setPlaybackRate] = useState(0.75);

const [count, setCount] = useState(0)
const [firstTime, setFirstTime] = useState(true)

const talkVolume = 1;


/*
const [playClickSFX] = useSound('/assets/audio/click-002.m4a', {
  volume:  (audioState) ? 0.5 : 0,
 // playbackRate: playbackRate
 // interrupt: true,
});
*/

const [playSong, { stop: stopAudio1 }] = useSound(audioMap.song1, {
  volume: (audioState) ? 0.1 : 0,
  loop: true,
  interrupt: true,
});

const [playSong2,  { stop: stopAudio2 }] = useSound(audioMap.song2, {
  volume: (audioState) ? 0.1 : 0,
  loop: true,
  interrupt: true,
});

const [playSong3,  { stop: stopAudio3 }] = useSound(audioMap.song3, {
  volume: (audioState) ? 0.2 : 0,
  loop: true,
  interrupt: true,
});

function setSong(num){

  //alert('song '+ num)

  stopAudio1();
  stopAudio2();
  stopAudio3();



  if (num === 1 ){
    playSong()
  }else if (num === 2){
    playSong2()
  }else if (num === 3 ){
      playSong3()
  }
}
  

useEffect(() => {
  
  if (!firstTime) {
    setSong(count%3)
    console.log('ncount', count%3)
  }
}, [count])



const [playSLTimeFor] = useSound(audioMap.slTimeForStarShades, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});


const [playSLStarShades] = useSound(audioMap.slStarShades, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [playSLBeStar] = useSound(audioMap.slBeStar, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});



const [playJAPickShades] = useSound(audioMap.jaPickShades, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [playJAChooseGlasses] = useSound(audioMap.jaChooseGlasses, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [playSLPickShades] = useSound(audioMap.slPickShades, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [playJAClickCamera] = useSound(audioMap.jaClickCamera, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [playBinkSayBooks] = useSound(audioMap.binkSayBooks, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});



const [playSLSayBooks] = useSound(audioMap.slSayBooks, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [playSLBestie] = useSound(audioMap.slBestie, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [playSLStar] = useSound(audioMap.slStar, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [playSLAlways] = useSound(audioMap.slAlways, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [playSLSend] = useSound(audioMap.slSend, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [playSLShare] = useSound(audioMap.slShare, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});

const [playSLSendBestie] = useSound(audioMap.slSendBestie, {
  volume: (audioState) ? talkVolume : 0,
  interrupt: true,
});


const [playClickSFX] = useSound(audioMap.click, {
  volume:  (audioState) ? 1 : 0,
 // playbackRate: playbackRate
 // interrupt: true,
});

const [playWhooshSFX] = useSound(audioMap.whoosh, {
  volume:  (audioState) ? 1 : 0,
 // playbackRate: playbackRate
 // interrupt: true,
});

const [playMultipopSFX] = useSound(audioMap.multipop, {
  volume:  (audioState) ? 1 : 0,
 // playbackRate: playbackRate
 // interrupt: true,
});

const [playBoopSFX] = useSound(audioMap.boop, {
  volume:  (audioState) ? 1 : 0,
 // playbackRate: playbackRate
 // interrupt: true,
});

const [playPopSFX] = useSound(audioMap.pop, {
  volume:  (audioState) ? 1 : 0,
 // playbackRate: playbackRate
 // interrupt: true,
});

const [playSnapshotSFX] = useSound(audioMap.snapshot, {
  volume:  (audioState) ? 1 : 0,
 // playbackRate: playbackRate
 // interrupt: true,
});

const [playDownloadSFX] = useSound(audioMap.download, {
  volume:  (audioState) ? 1 : 0,
 // playbackRate: playbackRate
 // interrupt: true,
});


  




  const drawCanvasRef = useRef(document.createElement("canvas"));
  const textureRef = useRef(null);
  const videoRef = useRef();
  const canvasRef = useRef();
  const outputRef = useRef();
  const overlayRef = useRef();
  const bubbleRef = useRef();
  const wholeRef = useRef();
  const poseRef=useRef({x:0,y:0,z:0})
  const initRef = useRef(0);

  const previewRef = useRef();

  const aspectMobile = 0.6666666667;
  const aspectDesktop = 1;//1.777777778;

  const selfieSize = 600; /// is this right???
  const selfieSizeMobile = Math.max(360, window.innerWidth - 60)


  const captureSize = (isMobile) ? selfieSizeMobile : selfieSize;

  //const { zAdjust } = useControls({ zAdjust: 15 })
  const pickedGlass = useStore((state) => state.pickedGlass)


  const segmentationWidth = (isMobile) ? selfieSizeMobile  : selfieSize;
  const segmentationHeight = (isMobile) ? selfieSizeMobile : selfieSize;
  //const segmentationPixelCount = segmentationWidth*segmentationHeight;
  //const segmentationMask = new ImageData(segmentationWidth, segmentationHeight);
  const segmentationMaskCanvas = document.createElement('canvas');
  segmentationMaskCanvas.width = segmentationWidth;
  segmentationMaskCanvas.height = segmentationHeight;
  //const segmentationMaskCtx = segmentationMaskCanvas.getContext('2d');



  //const vrmRef = React.useRef(null);
  const dzRef = React.useRef(0);
  const dxRef = React.useRef(0.5);
  const dyRef = React.useRef(0.5);
  //const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const setGlassWheelActive = useWheel((s) => s.setGlassWheelActive)
  const setGlassFollowActive = useWheel((s) => s.setGlassFollowActive)


  const [open, setOpen] = useState(false)
  // We turn this into a spring animation that interpolates between 0 and 1
  const props = useSpring({ open: Number(open) })

  let pointsArr =[];
  const params = {
    debug: true,//false,
    color: '#ffffff',
    thickness: 20,
    smooth: 1.5,
    vidWidth: (isMobile) ? selfieSizeMobile : selfieSize * aspectDesktop,
    vidHeight: (isMobile) ? selfieSizeMobile  : selfieSize
    //vidHeight: (isMobile) ? 200 : 225
  }

  const videoConstraints = {
    width: { min: (isMobile) ? selfieSizeMobile : selfieSize * aspectDesktop, },
    height: { min: (isMobile) ? selfieSizeMobile : selfieSize , },
   // aspectRatio: 1,
   aspectRatio: isMobile? aspectMobile : aspectDesktop,
   // aspectRatio: 0.6666666667,
    facingMode: "user"
  };

  const [value, setValue] = React.useState(params.thickness);

  const snapProps = useSpring(
    {
      to: 
        { opacity: (snapActive) ? .7 : 0},
      delay: 0,// (snapActive) ? 500 : 0,
      duration: 200,//(snapActive) ? 200 : 0,
      onRest() {
        setSnapActive(false);
       // setIsolateGlass(false)
      },
    }
  )


  const previewProps = useSpring(
    {
      to: {
        scale: (previewActive) ? 1.0 : 0.1,
      },
      delay: 0,
      from:{
        scale:0.1, 
      },
     
      config: {
        duration: (previewActive) ? 1000 : 500,
        easing: easings.easeInOutQuart,
      },
      
      onRest() {
        
      },
    }
  )

  const yProps = useSpring(
    {
     to: {
        top: (previewActive) ?  0 :  window.innerHeight + 100  },
       delay: (previewActive) ? 0 : 300,
       from:{top: window.innerHeight + 100},
     
      config: {
        duration: (previewActive) ? 500 : 1000,
        easing: easings.easeOutQuart,
      },
      
      onRest() {
        
      },
    }


  )

  const vidProps = useSpring(
    {
      to: {
        opacity: (trackingActive && webcamPermission) ? 1 : 0
      },
      config: config.gentle,
      from:{
        opacity: 0
      },
     delay: 1,
 
      onRest() {
  
      },
    }


  )


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  


  let activeEffect = 'mask';

 

  const onSelfieResults = async (results) => {

   // console.log('selfie', results)

   outputRef.current.width = videoRef.current.video.videoWidth;
    outputRef.current.height = videoRef.current.video.videoHeight;
    
    // Draw the overlays.
    let canvasCtx = outputRef.current.getContext('2d', { willReadFrequently: true });

  // if(menuRef.current !== 'glasses'){
  //  if(bkgdRef.current !== 0){
     
  
   

    
    canvasCtx.save();

    canvasCtx.globalCompositeOperation = 'copy'

    canvasCtx.clearRect(0, 0, segmentationWidth, segmentationHeight);

    canvasCtx.drawImage(
        results.segmentationMask, 
        0, 
        0, 
        segmentationWidth,
        segmentationHeight,
      );



    // Only overwrite existing pixels.
        if (activeEffect === 'mask' || activeEffect === 'both') {


          canvasCtx.globalCompositeOperation = 'source-in';
          // This can be a color or a texture or whatever...
          canvasCtx.fillStyle = '#00FF00';
          canvasCtx.fillRect(0, 0, segmentationWidth, segmentationHeight);


        } else {
          canvasCtx.globalCompositeOperation = 'source-out';
          canvasCtx.fillStyle = '#0000FF7F';
          canvasCtx.fillRect(0, 0, segmentationWidth, segmentationHeight);
        }

        canvasCtx.globalCompositeOperation = 'source-atop';
        canvasCtx.drawImage( results.image, 0, 0, segmentationWidth, segmentationHeight);
        canvasCtx.restore();

    //  }else{
    //    canvasCtx.clearRect(0, 0, segmentationWidth, segmentationHeight);
   //   }

  }

  //////////

  ////////////


    const onResults = async (results, debug) => {

    //console.log('zAdjust', zAdjust)

        // Draw landmark guides
        drawResults(results)
        if (vrmRef.current) animateVRM(vrmRef.current, results);
    }

    useEffect(() => {

        const faceMesh = new FaceMesh({
            locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
            },
        });
    
        faceMesh.setOptions({
            maxNumFaces: 1,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5,
        });
        //console.log(faceMesh)
        faceMesh.onResults(onResults);

/*
        const selfieSegmentation = new SelfieSegmentation({locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@0.1/${file}`;
         
        }});
        */ 
        const selfieSegmentation = new SelfieSegmentation({
          locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
          },
        });
    
        selfieSegmentation.setOptions({
          selfieMode:true,
          modelSelection: 1,
          effect: "mask",
        });

        selfieSegmentation.onResults(
         // onResultsSelfie
            onSelfieResults
        );
    
    
      

  
         if (videoRef.current && !camera) {
            const vidcamera = new cam.Camera(videoRef.current.video, {
              onFrame: async () => {

              //  console.log(' >>>> vportRef.current',  vportRef.current)

            //    vportRef.current === 0)

           //     console.log('bkgdREF. current', bkgdRef.current)

               // if(menuRef.current !== 'glasses'){
                if(siloRef.current === 1){
              
                    await selfieSegmentation.send({ image: videoRef.current.video });

               }else{
                  let canvasCtx = outputRef.current.getContext('2d');
                  canvasCtx.clearRect(0, 0, segmentationWidth, segmentationHeight);
               }
              
                await faceMesh.send({image: videoRef.current.video});


              },
              width: params.vidWidth,
              height: params.vidHeight
            });
            setCamera(vidcamera);
            vidcamera.start();
            setReady(true)
          }


      return () => {
        if (camera) {
          camera.stop();
          setCamera(null);
        }
      };


    }, [camera]);



   


   


    const drawResults = (results) => {

        if(canvasRef.current){
    
            canvasRef.current.width = videoRef.current.video.videoWidth;
            canvasRef.current.height = videoRef.current.video.videoHeight;
            let canvasCtx = canvasRef.current.getContext('2d', {willReadFrequently: true});

            canvasCtx.save();
            canvasCtx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

            var width = results.image.width;
            var height = results.image.height;

            let irisLeftMinX = -1;
            let irisLeftMaxX = -1;

            let xpos = 0
            let ypos = 0;

            if (results.multiFaceLandmarks) {

            for (const landmarks of results.multiFaceLandmarks) {
                for (const point of FACEMESH_LEFT_EYE) {
                const point0 = landmarks[point[0]];
                xpos = point0.x;
                ypos = point0.y;
                
                if (irisLeftMinX == -1 || point0.x * width < irisLeftMinX) {
                    irisLeftMinX = point0.x * width;
                }
                if (irisLeftMaxX == -1 || point0.x * width > irisLeftMaxX) {
                    irisLeftMaxX = point0.x * width;
                }
                }
        
            
            }
            }

            var dx = irisLeftMaxX - irisLeftMinX;
            var dX = 11.7;
        
            // Logitech HD Pro C922	Norm focal
            var normalizedFocaleX = 1.40625;
            var fx = Math.min(width, height) * normalizedFocaleX;
        // console.log('fx', fx)
            var dZ = (fx * (dX / dx)) / 10.0;
            dZ = dZ.toFixed(2);

            xpos = xpos.toFixed(2);
            ypos = ypos.toFixed(2);
            //console.log(dZ + " cm");

            dzRef.current = dZ;
            dxRef.current = xpos;
            dyRef.current = ypos;

            //if (debug){
            /*
            console.log('debug is '+debug)
        
            canvasCtx.fillStyle = "red";
            canvasCtx.font = "30px Arial";
            canvasCtx.fillText(dZ + " cm", 20, 50);

            canvasCtx.fillStyle = "green";
            canvasCtx.font = "30px Arial";
            canvasCtx.fillText("X:"+ xpos, 20, 80);

            canvasCtx.fillStyle = "orange";
            canvasCtx.font = "30px Arial";
            canvasCtx.fillText("Y:"+ ypos, 20, 110);
            */
        // }
            
        
            canvasCtx.restore();

        }
        
    }


function matchSquare(){
 

  const el = previewRef.current;
  const rect = el.getBoundingClientRect()
  console.log('app matchSquare: rect', rect);

  
  setSquareSize(rect)
}

// Animate Rotation Helper function
const rigRotation = (
  name,
  rotation = { x: 0, y: 0, z: 0 },
  dampener = 1,
  lerpAmount = 0.45//0.85// 0.45
) => {
  if (!vrmRef.current) {return}
  const Part = vrmRef.current;
 // console.log('Part', Part)
  if (!Part) {return}
  
  let euler = new THREE.Euler(
    rotation.x * dampener * -1,
    rotation.y * dampener,
    rotation.z * dampener * -1
  );
  let quaternion = new THREE.Quaternion().setFromEuler(euler);
  Part.quaternion.slerp(quaternion, lerpAmount); // interpolate
};

// Animate Position Helper Function
const rigPosition = (
  name,
  position = { x: 0, y: 0, z: 0 },
  dampener = 1,
  lerpAmount = 0.45
) => {

  //console.log('vport length',  vportRef.current)

  if (!vrmRef.current) {return}

    const Part = vrmRef.current;
    const sizeRatio = isMobile ? (360/600) : 1;

   // console.log('viewportHeight num', vportRef.current)
   // console.log('vport num', vport)
   // console.log('vport data', vportRef.current)

    const yOffsetPercentage = 0.1;
   // const vHeight =  3.152987888789835/2 * yOffsetPercentage;
    const offsetY = isMobile ? (0.1 * vportRef.current)/2 : 0.001; //2; 

    //const vHeight = isMobile ? 3.152987888789835/2 * yOffsetPercentage : 0;
   // const vHeight = vport * yOffsetPercentage;
   // console.log('vport vheight', vHeight)

    let xpos = (0.5 - dxRef.current) * (dzRef.current / (1.40625 + 0.3)) * sizeRatio
    //let ypos =  (0.5 - dyRef.current + vHeight) * (dzRef.current / (1.40625 + 0.25 + vHeight)) * sizeRatio 
    let ypos =  (0.5+offsetY - dyRef.current ) * (dzRef.current / (1.40625 + 0.25 +offsetY )) * sizeRatio 
    


  //  let ypos =  (0.5 - dyRef.current ) * (dzRef.current / (1.40625 + 0.25 )) * sizeRatio 
    

    if (!Part) {return}

    let vector = new THREE.Vector3(
        xpos + 0.5,
        ypos - 0.5,
        dzRef.current * -1 
    )


    Part.position.lerp(vector, lerpAmount); // interpolate
 } // end RigPosition


const rigFace = (riggedFace) => {
    if(!vrmRef.current){return}

    rigRotation("Neck", riggedFace.head);
    rigPosition("Head", riggedFace.head.position);

}


const animateVRM = (vrm, results) => {

  
  if (!vrm) {
    return;
  }   

  // Take the results from `Holistic` and animate character based on its Face, Pose, and Hand Keypoints.
  let riggedFace;

  const faceLandmarks = results.multiFaceLandmarks[0];

  // Animate Face
  if (faceLandmarks) {
   riggedFace = Kalidokit.Face.solve(faceLandmarks,{
      runtime:"mediapipe",
      video:videoRef.current.video,
      imageSize:{
        width: params.vidWidth,
        height: params.vidHeight,
    }
   });
 //  console.log('riggedFace', riggedFace)
   rigFace(riggedFace)
  }

};
  
/*
const setupVideo = () =>{

  const vidcamera = new Camera(videoRef.current.video, {
    onFrame: async () => {
     // await holistic.send({image: videoRef.current.video});

     // console.log('ONFRAME________')

      if (segmentActive){
         await selfieSegmentation.send({image: videoRef.current.video});
      }
     // if (trackingActive){
        await faceMesh.send({image: videoRef.current.video});
        if (initRef.current === 0) {
       
          setReady(true)
          initRef.current=1;
          console.log('ready=', ready)
        }
     // }
  
    },
    width: params.vidWidth,
    height: params.vidHeight
  });
  vidcamera.start();
 // console.log('set vidcamera',vidcamera)
}

 */



  useEffect(() => {
    let timer;
    if (gameState === 'intro'){
        setTitleActive(true);
        setPreviewActive(true);

    }else if (gameState === 'freeplay'){

     
      if (!pickedGlass) {
        setMenuMode('glasses')
      }else{
        //on replay, reset ...
        setMenuMode('neutral')
      }  

      setTitleActive(false);
      setBkgdActive(true);
      setGlassesActive(true)
      setGlassFollowActive(true)
      setGlassWheelActive(true)
      setPreviewActive(true);
      if (!segmentActive) {
        setSegmentActive(true);
      }

    
    }else if (gameState === 'polaroid'){

      ReactGA.event({
        category: "interaction",
        action: "Create polaroid",
      });

        setFirstTime(false)
        randomEnding();
        setGlassFollowActive(false)
        setBkgdActive(false);
        setGlassesActive(false)
        setGlassWheelActive(false)
        setPreviewActive(false);
        setSegmentActive(false);
        setMenuMode('photo')
    }
    return () => clearTimeout(timer);
  }, [gameState])

  useEffect(() => {

    if (menuRef.current) {
      menuRef.current = menuMode

      if (!siloOn){

        if (menuMode === 'scenes'){
          siloRef.current = 1;
          setSiloOn(true)
         // alert('silo')
        }else{
          siloRef.current = 0;
        }

      }

    

    }

  },[menuMode])

  const [timeoutWebcam, clearTimeoutWebcam] = useTimeout(() => {
  
     webcamRef.current = 0;
     setWebcamOn(false)
   
   }, 600);

   const [timeoutSilo, clearTimeoutSilo] = useTimeout(() => {
  
    siloRef.current = 0;

  //  alert('timeout webcam '+webcamRef.current)
  
  }, 600);

  useEffect(() => {
   // clearTimeoutWebcam()
   // clearTimeoutSilo();

    if (bkgdIndex === 0){
     // alert('bkgd zero')
     // cameraModeRef.current = 1;
      webcamRef.current = 1;
      setWebcamOn(true)
      timeoutSilo();

    }else{
     // cameraModeRef.current = 0;
      timeoutWebcam();
      siloRef.current = 1;
      setSiloOn(true)
    }

    if (bkgdRef.current) bkgdRef.current = bkgdIndex
    console.log('---- bkgd '+ bkgdIndex)
    console.log('---  bkgdRef '+  bkgdRef.current)

  },[bkgdIndex])


  useEffect(() => {
    if (clickedScene) { 
      setGameState('freeplay')
    }
  }, [clickedScene])

  useEffect(() =>{

   // if (vport !== 'undefined'){
      vportRef.current = viewportHeight;
      //console.log('!!!!!!!!    vportRef.current', vportRef.current)
   // }
  }, [viewportHeight])
  



  useEffect(() => {

    ReactGA.initialize('G-GR6KGSMW5H');


    ReactGA.send({
      hitType: 'pageview',
      page: '/star-shades',
      title: 'Star Shades Game'
    })

/*

    const vidcamera = new Camera(videoRef.current.video, {
      onFrame: async () => {
       // await holistic.send({image: videoRef.current.video});

        await faceMesh.send({image: videoRef.current.video});
        await selfieSegmentation.send({image: videoRef.current.video});
        if (initRef.current === 0) {
          setReady(true)
          initRef.current=1;
          console.log('ready=', ready)
        }
    
      },
      width: params.vidWidth,
      height: params.vidHeight
    });
    vidcamera.start();
   // setReady(true);
*/
  }, [])

  const handleUserMedia = () => {
    console.log('webcam go!');
    setTrackingActive(true)
   // setupVideo();
    setAudioState(true)
    setSong(1)

   // setReady(true) /// this is a fix, but why doesn't it work from the useEffect when creating the video??

    //audio: make random...
    //playSLBeStar();
    randomOpening();
    
  }

  function randomOpening(){

    const rand = Math.random()*3;
    if (rand <1 ){
      playSLStarShades()
    }else if (rand <2){
      playSLBeStar()
    }else if (rand <3 ){
      playSLTimeFor()
    }
  }

  function randomEnding(){
    const rand = Math.random()*3;
    
    if (rand <1 ){
      playSLBestie()
    }else if (rand <2){
      playSLStar();
    }else if (rand <3 ){
      playSLAlways()
    }
  }


  const clickedItem = () => {
    if (itemCounter == 0) setGameState('instScene')
    setItemCounter(itemCounter+1);
  }




const setupPicture = () => {

  setSnapActive(true)
  setTimeout(exportAsPicture, 800);
  
}

const exportAsPicture = async () => {


  ReactGA.event({
    category: "interaction",
    action: "Download or Share polaroid",
  });

    // for onscreen display ....

    playSnapshotSFX();

    const html = document.getElementsByTagName('HTML')[0]
    const body = document.getElementsByTagName('BODY')[0]

    setSnapActive(false)

    var data = wholeRef.current;
    console.log('data', data)
    console.log('data.scrollWidth', data.scrollWidth)
    console.log('data.clientWidth', data.clientWidth)

    const picOffset = isMobile ? data.clientHeight*.1 + 11 : 0;

    const canvas = await html2canvas(data, {
      x: (html.clientWidth - captureSize)/2, 
      y: (html.clientHeight - captureSize - picOffset)/2,
      width: captureSize, 
      height: captureSize,
    })

    console.log('canvas', canvas)

    const cameraImage = canvas.toDataURL('image/png', 1.0)
    setCamImg(cameraImage)

    const polaroid = new Image();
    polaroid.id = "pic";
    polaroid.src = canvas.toDataURL();
    await polaroid.decode();
   // document.getElementById('polaroid').appendChild(polaroid);
    console.log('polaroid', polaroid.src)
    setPolaroidImg(polaroid.src)
    setSnapActive(false);

    

    /// now create download image....

    setTimeout(function(){
     // alert('framed timeout')
      const snapshot = document.getElementById('polaroid')

      html2canvas(snapshot)
      .then((polaroidCanvas) => {
        console.log('canvas', canvas)
   
       // const polaroidCanvas =  await html2canvas(snapshot)

        const backCanvas = document.createElement('canvas');
        backCanvas.width = 400;
        backCanvas.height = 480;
        const backCtx = backCanvas.getContext('2d', { willReadFrequently: true });
        //  backCtx.imageSmoothingQuality = "high"
          
        backCtx.translate(200, 220);
        backCtx.rotate(5*Math.PI/180);
        backCtx.scale(1.09, 1.09);
        backCtx.translate(-200, -220);

        backCtx.fillStyle = 'white';
        backCtx.fillRect(-50,-50,500, 600);
        
        backCtx.drawImage(polaroidCanvas, 2,0, 400, 480);

        const frameImage = backCanvas.toDataURL('image/jpeg', 1.0)
        const mime = frameImage.split(',')[0].split(':')[1].split(';')[0];
        return frameImage
      })
      .then((image) => {
        setFramedOutput(image)
      })

       
    }, 2000);

    

    
   // console.log('mime', mime)

   setGameState('polaroid')
      
      //  setTimeout(() => {setCamImg(null)}, 1000);
     //   setOverlayImg(image)
        html.style.width = null
        body.style.width = null
  }

 

  const saveAs = (blob, fileName) => {
    
// not best way to detect it???
   // if (!isMobile){

   // feature detection....
   // if (!navigator.share) {

     

      var elem = window.document.createElement('a')
      elem.href = blob
      elem.download = fileName
      // elem.style = 'display:none;'
      ;(document.body || document.documentElement).appendChild(elem)
      if (typeof elem.click === 'function') {
        elem.click()
      } else {
        elem.target = '_blank'
        elem.dispatchEvent(
          new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          })
        )
      }
      URL.revokeObjectURL(elem.href)
      elem.remove()

      /*
    }else{

     // alert('navigator')

      fetch(blob)
      .then(res => res.blob())
      .then(blob => {
       
        const file = new File([blob], fileName, {type: "image/jpeg" })
        console.log('file:', file)

        const filesArray = [file]
        const shareData = {
          files: filesArray,
        };
        navigator.share(shareData).then(() => {
          console.log('Shared successfully');
         // alert('shared')
        })
      })

     
    }
      */
  }


  
  const shareSnapshot = async () => {


    const html = document.getElementsByTagName('HTML')[0]
    const body = document.getElementsByTagName('BODY')[0]

    const data = document.getElementById('polaroid')
    /*
    console.log('data', data)
    console.log('data.scrollWidth', data.scrollWidth)
    console.log('data.clientWidth', data.clientWidth)

    const canvas = await html2canvas(data);

    const backCanvas = document.createElement('canvas');
    backCanvas.width = 400;
    backCanvas.height = 480;
    const backCtx = backCanvas.getContext('2d', { willReadFrequently: true });
    //  backCtx.imageSmoothingQuality = "high"
      
    backCtx.translate(200, 220);
    backCtx.rotate(5*Math.PI/180);
    backCtx.scale(1.09, 1.09);
    backCtx.translate(-200, -220);

    backCtx.fillStyle = 'white';
    backCtx.fillRect(-50,-50,500, 600);
    
    backCtx.drawImage(canvas, 2,0, 400, 480);

    const image = backCanvas.toDataURL('image/jpeg', 1.0)
    const mime = image.split(',')[0].split(':')[1].split(';')[0];
    console.log('mime', mime)
*/
   // const response = await fetch(image);
   // const blob = await response.blob();


   // direct url works for webshare in Chrome iOS, but image generation before fails

    const imageUrl = framedOutput;//'/assets/images/title01.png';

    let blob = await (await fetch(imageUrl)).blob();

    //let blob = await (await fetch(image)).blob();

   // const file = new File([blob], 'WayPastBooks-StarShades.jpg', {type: mime })
    const file = new File([blob], 'WayPastBooks-StarShades.jpg', {type: "image/png" })

    const filesArray = [file]
    const shareData = {
      files: filesArray,
      lastModified: new Date().getTime(),
     // title: 'Click "Save to Photos" below',
     // text: 'Check out this image!',
    };

  

    navigator.share(shareData).then(() => {
     //alert('Success!');
     console.log('success')
    })
    .catch((error) => {
     console.log('Sharing failed:', error);
    });

    html.style.width = null
    body.style.width = null

/*

    html2canvas(data)
      .then((canvas) => {
        console.log('canvas', canvas)

        const backCanvas = document.createElement('canvas');
        backCanvas.width = 400;
        backCanvas.height = 480;
        const backCtx = backCanvas.getContext('2d');
        //  backCtx.imageSmoothingQuality = "high"
          
        backCtx.translate(200, 220);
        backCtx.rotate(5*Math.PI/180);
        backCtx.scale(1.09, 1.09);
        backCtx.translate(-200, -220);

        backCtx.fillStyle = 'white';
        backCtx.fillRect(-50,-50,500, 600);
        
        backCtx.drawImage(canvas, 2,0, 400, 480);

        const image = backCanvas.toDataURL('image/jpeg', 1.0)

        return image
      })
      .then((image) => {

        fetch(image)
        .then(res => res.blob())
        .then(blob => {
         // alert('got it')
          const url = 'https://wpb-star-shades.netlify.app/assets/images/title01.png'
          const file = new File([blob], 'WayPastBooks-DrawYourOwnDogs.jpg', {type: "image/jpeg" })

          const filesArray = [file]
          const shareData = {
            files: filesArray,
          };
          navigator.share(shareData).then(() => {
            console.log('Shared successfully');
          })
          .catch((error) => {
            console.error('Sharing failed:', error);
          });
        })


        html.style.width = null
        body.style.width = null
      })
*/
  }

  const exportSnapshot = () => {
   

    playDownloadSFX();

    const html = document.getElementsByTagName('HTML')[0]
    const body = document.getElementsByTagName('BODY')[0]

    const data = document.getElementById('polaroid')
    console.log('data', data)
    console.log('data.scrollWidth', data.scrollWidth)
    console.log('data.clientWidth', data.clientWidth)


    html2canvas(data)
      .then((canvas) => {
        console.log('canvas', canvas)

        const backCanvas = document.createElement('canvas');
        backCanvas.width = 400;
        backCanvas.height = 480;
        const backCtx = backCanvas.getContext('2d', { willReadFrequently: true });
        //  backCtx.imageSmoothingQuality = "high"
          
        backCtx.translate(200, 220);
        backCtx.rotate(5*Math.PI/180);
        backCtx.scale(1.09, 1.09);
        backCtx.translate(-200, -220);

        backCtx.fillStyle = 'white';
        backCtx.fillRect(-50,-50,500, 600);
        
        backCtx.drawImage(canvas, 2,0, 400, 480);

        const image = backCanvas.toDataURL('image/jpeg', 1.0)

        return image
      })
      .then((image) => {
        
      
        saveAs(image, 'WayPastBooks-StarShades.jpg')

        html.style.width = null
        body.style.width = null
      })
  }
     

  //////////////

  return (
    <div id="WholeThing" ref={wholeRef} >

      <LogoBug 
        showBug={true} 

    //  currentColor={feelingsArr[activeFeeling].color}
      //currentColor={shadeColor("#EEE71F",-2)}
      currentColor={"#EEE71F"}
      />
{/*
      <div className="debug-controls on-off">
     <h4>{gameState}</h4> 

        {debug &&
        <>
            <p ref={poseRef}></p>
            <h2>g: {glassesIndex}</h2>
            <h2>w: {wheelIndex}</h2>
            </>
   }
        <button onClick={() => {
playPopSFX()
          setDebug(!debug)}
        }>
          {(debug) ? "Debug On " : "Debug Off" }
        </button>
       
      </div>
*/}
      <SelfieControls 
        key={'selfcontrol'}
        //reveal={bkgdActive}
        reveal={glassesActive}
        gameMode={gameState}
        menuState={true}
        menuMode={menuMode}
        setMenuMode={setMenuMode}


        playPopSFX={playPopSFX}
       // prevBkgd={prevBkgd}
       // exportAsPicture={exportAsPicture}
        exportAsPicture={setupPicture}
       // nextBkgd={nextBkgd}
        selfieSize={(isMobile) ? selfieSizeMobile : selfieSize}
        setKill={setKill}
        kill={kill}
   
      />



 
  {(gameState === 'instGlasses' || gameState === 'instScene'  || gameState === 'instPhoto' || gameState === 'freeplay' || gameState === 'polaroid'  ) &&

<>
      <SpeechBubble ref={bubbleRef} 
        gameMode={gameState} 
       // balloonActive={balloonActive} 
       // setBalloonActive={setBalloonActive}
        balloonCounter={balloonCounter}
        playPopSFX={playPopSFX}
        setKill={kill}
        kill={kill}
        menuMode={menuMode}

        />

        <SequentialActions
        gameMode={gameState} 
        menuMode={menuMode}
        />


</>

}
      


      <div className="selfie-window">
              <web.div ref={previewRef} className="preview round-edge" id="previewbox" style={{ 
                width: (isMobile) ? selfieSizeMobile : selfieSize, 
                height: (isMobile) ? selfieSizeMobile : selfieSize,
                ...yProps, 
                ...previewProps,
                
                 }}>
                 
             
                  <web.div className={`webcam-holder round-edge ${(webcamOn) ? 'shown' : 'hidden'}`}  style={vidProps} >
                    <Webcam
                      className="round-edge"
                      width={params.vidWidth}
                      height={params.vidHeight}
                     // mirrored
                      id="webcam"
                      audio={false}
                      ref={videoRef}
                      onUserMedia={handleUserMedia}
                     // onUserMediaError={}
                      videoConstraints={videoConstraints} 
                      screenshotFormat="image/jpeg"
                    //  style={{ display: 'none' }}
                    />
                  </web.div>
            
            
                  <canvas ref={canvasRef} 
                    className={`guides mediapipe-overlay`}
                    style={{ width: selfieSize, height: selfieSize }
                  }></canvas>
            
                 

                  {/*backgroundConfig.type !== 'none' &&
                      <div className={(bkgdActive) ? "background show" : "background hide"} >
                        <img
                        className="bkgd-img"
                      //  src="/assets/backgrounds/cartoon-trees.jpg"
                        src={backgroundConfig.url}
                        alt=""
                      // onLoad={props.onLoad}
                      />
                      </div>
*/}
{(gameState === 'freeplay' ) &&
                  <BackgroundPanel 
                   // backgroundArr={backgroundArr}
                   // counter={counter}
                   // gameState={gameState}
                  />
}


                  <canvas ref={outputRef} className={(segmentActive && siloOn) ? "selfie show" : "selfie hide"} style={{ 
                    width: (isMobile) ? selfieSizeMobile : selfieSize, 
                    height: (isMobile) ? selfieSizeMobile : selfieSize 
                  }}></canvas>

       
       { gameState === 'intro' &&

                  <web.div className="introMessage" >

                      <TitleCard 
                        reveal={titleActive}
                        //reveal={false}
                        ready={ready}
                        permission={webcamPermission}
                        setPermission={setWebcamPermission}
                        setGameState={(e) =>  setGameState(e)}
                        />

                  </web.div>
}
                  
              </web.div>
      </div>
{/*
      <LogoBug showBug={true} currentColor={color}/>
*/}
       <button 
          className="sound-button"
       //   onMouseEnter={playClickSFX}
          onClick={() => {
            setAudioState(!audioState)
            playClickSFX();

           // setSegmentActive(!segmentActive)
          }
          }
          >
            {audioState ?
             <HiSpeakerWave />
             :
             <HiSpeakerXMark />
            }
            
      </button>
{/*
      <StarScene 
      width={document.getElementsByTagName('HTML')[0].clientWidth}/>
*/}

      <ThreeSpace 
      
        menuMode={menuMode}
        setMenuMode={setMenuMode}
        previewActive={previewActive}
        glassesActive={glassesActive}
        gameMode={gameState}
        clickedItem={clickedItem}
        debug={debug}
        matchSquare={matchSquare}
        squareSize={squareSize}
        isolateGlass={isolateGlass}
        playWhooshSFX={playWhooshSFX}
        playMultipopSFX={playMultipopSFX}
        playBoopSFX={playBoopSFX}
        
        
        
      />


{/*
  <MaskScene />
*/}
      
    
   {/*

          <ThreeSceneScroll
           // vrmRef={vrmRef}
            vidRef={videoRef}
            drawCanvasRef={drawCanvasRef}
            textureRef={textureRef}
            setAllowControls={setAllowControls}
            menuMode={menuMode}
            setMenuMode={setMenuMode}
            previewActive={previewActive}
            gameState={gameState}
         //   xDebugRef={xDebugRef}
         //   yDebugRef={yDebugRef}
         //   zDebugRef={zDebugRef}
            glassesActive={glassesActive}
            permission={webcamPermission}
            gameMode={gameState}
            clickedItem={clickedItem}
            chosenGlasses={chosenGlasses}
            setChosenGlasses={setChosenGlasses}
            debug={debug}
            matchSquare={matchSquare}
            squareSize={squareSize}
            isolateGlass={isolateGlass}
            playWhooshSFX={playWhooshSFX}
            playMultipopSFX={playMultipopSFX}
            playBoopSFX={playBoopSFX}
            
          />
   */}

 

        <web.div className="snapscreen" data-html2canvas-ignore  style={snapProps}></web.div>


      {(gameState === 'freeplay' || gameState === 'polaroid') &&
        <>
            <SnapshotPanel 
            // exportSnapshot={exportSnapshot}
              gameState={gameState}
              bgImg={polaroidImg}
             // hover={hover}
              size={(isMobile) ? selfieSizeMobile : 500 } 
              />
            <PrintControl 
                  gameState={gameState}
                  exportSnapshot={exportSnapshot}
                  closeSnapshot={() => {
                  //  setGlassWheelActive(true)
                   playClickSFX()
                    setGameState('freeplay')
                  }}
                  onHover={(e) => setHover(e)}
              />
            <ActionControls 
                //reveal={true}
                gameMode={gameState}
                exportSnapshot={exportSnapshot}
                shareSnapshot={shareSnapshot}
                playPopSFX={playPopSFX}
              />
             

        </>
      }
{/*
<div style={{ position: 'absolute', top: 20, left: 20, color: 'red' }}>
      <p>Viewport Width: {viewportWidth.toFixed(2)}</p>
      <p>Viewport Height: {viewportHeight.toFixed(2)}</p>
    </div>

    */}
    {/*
<DisplayViewportSize />
  */}
     {/*

     <Debug 
        previewActive={previewActive}
        setPreviewActive={setPreviewActive}
        setGameState={setGameState}
        ready={ready} 
        gameState={gameState}
        camImg={camImg}
        balloonActive={balloonActive}
        setBalloonActive={setBalloonActive}
        setSong={setSong}
     />
     */}
     

     
    </div>
  );
}

