
import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { useTransition, useSpring, animated, config, easings} from '@react-spring/web'
import { useStore } from '../store'
import useTimeout from '../hooks/useTimeout';

import audioMap from '../resources/audio';
import useSound from 'use-sound';
import Message from "./Message";

//import "./styles.css";

const messages = [
 // "Pick Your Glasses",
  "Scroll & Choose Glasses",
  "Change Your Background",
  "Take Your Photo",
  "Download & Share"
];


const SpeechBubble = ({ 
  gameMode, 
 // balloonActive, 
 // setBalloonActive, 
  setKill,
  kill,
  menuMode,
 // playPopSFX,
  balloonCounter }, ref) => {

  const [fastOut, setFastOut] = useState(false);

  const [balloonPos, setBalloonPos] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const pickedGlass = useStore((state) => state.pickedGlass)
  const pickedScene = useStore((state) => state.pickedScene)
  const pickedPhoto = useStore((state) => state.pickedPhoto)
  const {setAudioState} = useStore((state) => state.actions)
  const audioState = useStore((state) => state.audioState)



  const balloonActive = useStore((state) => state.balloonActive)
  const balloonMessage = useStore((state) => state.balloonMessage)
  const balloonAudio = useStore((state) => state.balloonAudio)
  const {setBalloonState} = useStore((state) => state.actions)


  const bubbleRef = useRef();
  const tailRef = useRef();
  const tailShadowRef = useRef();
  const cursorOuterRef = useRef();
//const SpeechBubble = ({messages, talk, topic}) => {
  const [currentMessage, setCurrentMessage] = useState(messages[0]);
 // const [msgArr, setMsgArr] = useState(messages);
 // const happyPress = useKeyPress('ArrowRight');
/*
  useEffect(() => {

    setFastOut(!fastOut);
  }, [gameMode]);

*/
/*
  useEffect(() => {
   // alert(messages)
    setMsgArr(messages)
  }, [messages]);
*/
/*
useTransition(items, {
  enter: item => [
    { opacity: item.opacity, height: item.height },
    { life: '100%' },
  ],
  leave: item => async (next, cancel) => {
    await next({ life: '0%' })
    await next({ opacity: 0 })
    await next({ height: 0 })
  },
  from: { life: '0%', opacity: 0, height: 0 },
})

const transitions = useTransition(gameMode, {
  from: {
    opacity: 0,
    height: 0,
    innerHeight: 0,
    transform: 'perspective(600px) rotateX(0deg)',
    color: '#8fa5b6',
  },
  enter: [
    { opacity: 1, height: 80, innerHeight: 80 },
    { transform: 'perspective(600px) rotateX(180deg)', color: '#28d79f' },
    { transform: 'perspective(600px) rotateX(0deg)' },
  ],
  leave: [{ color: '#c23369' }, { innerHeight: 0 }, { opacity: 0, height: 0 }],
  update: { color: '#28b4d7' },
})
*/

const { innerHeight, top, opacity, iconTransform, ...balloonProps } = useSpring({
//const vidProps = useSpring(
 // {
   // to: [{
  //    scale: (previewActive) ? 1.0 : 0.1},
  //   { top: (previewActive) ?  0 : (window.innerHeight) / 5   }],

    to: isActive ? [
      { 
        scale: 1, 
        innerHeight: 90,
     }, 
     {
        opacity: 1,
     }
    ] : [
      {
        opacity: 0,
     },
      { 
         scale: 0, 
         innerHeight: 0,
      }, 
      ],
    //  delay: (trackingActive) ? 0 : 300,
   // duration: 1,//(snapActive) ? 200 : 0,
  //  config: fastOut ? config.stiff : config.gentle,
    //config:  easings.easeOutB,
    from:{
      opacity: 0,
      scale: 0,
      innerHeight: 0,
    },
  // delay: fastOut ? 0 : 1,
   config: {
      duration: 250,
      easing: (isActive) ? easings.easeOutBack : easings.easeInBack
    },
    
    onStart: () => {
      console.log('SPEECH BALLOON: start')
      // setCurrentMessage('')
      if (balloonActive) setCurrentMessage(balloonMessage)
     },
     onRest: () => {
      console.log('SPEECH BALLOON: end')
      
     },
  }


)

/*

const transitions = useTransition(balloonActive, {
  key: balloonActive,
  from: { 
      opacity: 0,
      scale: 0,
      innerHeight: 0,
    //  transform:`translateXY(100px, -50px)`,
    //  top: 0
  },
  enter: [
    { 
      //opacity: 1,
      //scale:1
       scale: 1, innerHeight: 80 ,
   }, {
     opacity: 1,
   //  top: '50%',
   //  transform:`translateXY(0px, 0px)`,
    
    }],
  leave: [ 
    {
      innerHeight: 0, 
    //  top: 0, 
      opacity: 0, 
     // transform:`translateXY(100px, -50px)`,
    },
    { 
    //  opacity: 0,
      scale: 0
   },
  
  
  
  ],
 // config: { duration: 600 },
 delay: 300,
 //delay: fastOut ? 500 : 0,
 config: {
     duration: 500,
    easing: easings.easeInOutBack,
 },
//   config: config.gentle,
  onStart: () => {
   // setCurrentMessage('')
  },
  onRest: (_a, _b, item) => {
    console.log('bubble rest')

  
   // if (index === item) {
     //  set(state => (state + 1) % slides.length)
   // }
  },
  exitBeforeEnter: true,
})
  
*/

/*
const [playJAPickShades] = useSound(audioMap.jaPickShades, {
  volume: (audioState) ? 1 : 0,
});

const [playSLPickShades] = useSound(audioMap.slPickShades, {
  volume: (audioState) ? 1 : 0,
});

const [playChangeBkgd] = useSound(audioMap.changeBkgd, {
  volume: (audioState) ? 1 : 0,
});

const [playJAClickCamera] = useSound(audioMap.jaClickCamera, {
  volume: (audioState) ? 1 : 0,
  interrupt: true
});

const [playSLSendBestie] = useSound(audioMap.slSendBestie, {
  volume: (audioState) ? 1 : 0,
});

const [playSLSend] = useSound(audioMap.slSend, {
  volume: (audioState) ? 1 : 0,
});

const [playPopSFX] = useSound(audioMap.pop, {
  volume:  (audioState) ? 1 : 0,
 // playbackRate: playbackRate
 // interrupt: true,
});

const [timeoutMsgShades, clear] = useTimeout(() => {
  console.log('Trigger1')
  setBalloonPos(0)
  setBalloonActive(true);
  setCurrentMessage(messages[0])
  playPopSFX();
  timeoutSpeakShades();
}, 2000);

const [timeoutSpeakShades] = useTimeout(() => {

  playJAPickShades();
  
}, 1000);


const [timeoutMsgScene] = useTimeout(() => {

  console.log("--------- SPEECH: msgScene trigger")
  setBalloonPos(0)
  console.log('Trigger1')
  setBalloonActive(true);
  setCurrentMessage(messages[1])
  playPopSFX();

  timeoutSpeakScene();
}, 2000);

const [timeoutSpeakScene] = useTimeout(() => {

  playChangeBkgd();

  //playSLPickShades();
 // timeoutHideBalloon();
  
}, 1000);



const [timeoutMsgPhoto] = useTimeout(() => {
  setBalloonPos(0)
  console.log('Trigger2')
  setBalloonActive(true);
  setCurrentMessage(messages[2])
  playPopSFX();
  timeoutSpeakPhoto();
  setPickedPhoto(true)
}, 4000);

const [timeoutSpeakPhoto] = useTimeout(() => {

  playJAClickCamera();
 // timeoutHideBalloon();
  
}, 1000);


const [timeoutMsgDownload] = useTimeout(() => {
  console.log('Trigger3')
  setBalloonPos(2)
  setBalloonActive(true);
  setCurrentMessage(messages[3])
  playPopSFX();
  timeoutSpeakDownload();
  

}, 3500);

const [timeoutSpeakDownload] = useTimeout(() => {

  playSLSend();
  timeoutHideBalloon();
  
}, 1000);

const [timeoutHideBalloon] = useTimeout(() => {

  setBalloonActive(false);
}, 4000);
*/
/*
useEffect(() => {

  if (!balloonActive){
    setFastOut(true)
    setCurrentMessage('')
  }else{
    setFastOut(false)
    

  }
  
}, [balloonActive]);
*/

/*
useEffect(() => {

  if (gameMode ==='freeplay'){

    console.log('SPEECH: mode: '+ gameMode)

    if (menuMode==='glasses'){
      setBalloonActive(false);
      if (!pickedGlass){
        timeoutMsgShades()
      }else{
        setBalloonActive(false)
      }
      
    }else if (menuMode==='scenes'){
      setBalloonActive(false);
      if (!pickedScene){
        timeoutMsgScene()
      }else{
        setBalloonActive(false)
      }
    }else{
      setBalloonActive(false)
    }

    if (pickedGlass && pickedScene && !pickedPhoto){

      setBalloonActive(false);
      timeoutMsgPhoto();

    } else if (pickedPhoto){
      setBalloonActive(false);
    }


  }else if (gameMode ==='polaroid'){


    
    setBalloonActive(false);
    timeoutMsgDownload()

  
  }



    return () => {}
    
  }, [gameMode, pickedGlass, pickedScene, pickedPhoto, menuMode]);

  */

  /*
  useEffect(() => {

    setFastOut(!fastOut);
  }, [gameMode]);
*/
/*
  const props = useSpring({
    transform: (balloonPos === 1)? "translate3d(0,400px,0)" :  
      (balloonPos === 2)? "translate3d(-150px,350px,0)"  : "translate3d(0,0,0)",
    config: config.wobbly,
    delay: 500
  });

  const spring = useSpring({
    from: {
      transform: "translate3d(0,0,0)" 
    },

    to: {
      transform: (balloonPos === 1)? "translate3d(0,400px,0)" :  
      (balloonPos === 2)? "translate3d(-150px,350px,0)"  : "translate3d(0,0,0)",
    },
    config: {
      mass: 6,
    },
  })
*/
/*
  useEffect(() => {

   killAll();
  }, [kill]); 

  function killAll(){

    //alert('kill')
    
   

    console.log('????? SPEECH : kill ////////////////////')
    clearTimeout(timeoutHideBalloon)
    clearTimeout(timeoutMsgShades)
    clearTimeout(timeoutMsgScene)
    clearTimeout(timeoutMsgPhoto)

    clearTimeout(timeoutSpeakShades)
    clearTimeout(timeoutSpeakScene)
    clearTimeout(timeoutSpeakPhoto);

    setBalloonActive(false);

    


  }
*/

useEffect(() => {

  //alert('balloonActive:'+isActive)
  console.log(':::::: [] balloonActive: '+ balloonActive)

  setCurrentMessage(balloonMessage)
 
  //setballoonActive(balloonActive)
   
 }, []);

useEffect(() => {

 //alert('balloonActive:'+isActivate)
 console.log(':::::: balloonActive: '+ balloonActive)

 setIsActive(balloonActive)
  
}, [balloonActive]);



  return (

    <div
          ref={cursorOuterRef}
          className="word-balloon bubble speech"
          data-html2canvas-ignore
          /*
          onClick={(e) => {
             console.log("SPEECH click", balloonActive)
             //setIsActive(!isActive)
            
             setBalloonState(!balloonActive, 'xoxo: '+ !balloonActive, 0)
          }}
             */
          style={{
            transform: (balloonPos === 2)? "translate3d(-540px,0px,0) scaleX(-1)"  : "translate3d(0,0,0) scaleX(1)"
          }}
     
        >
          
    
 
            <div className="DialogWindow bubble-text"
             style={{
              transform: (balloonPos === 2)? "scaleX(-1)"  : "scaleX(1)"
            }}
            >

            {/*  <Message message={currentMessage} fastOut={fastOut}/> */}
            <animated.div  className="DialogMessage" style={{ opacity: opacity }}>
                {currentMessage}
            </animated.div>

            </div>

 
              <animated.div ref={tailRef} className="tail" style={{ height: innerHeight,   top:top, opacity: opacity }}></animated.div>

              <animated.div
                    className="balloon-body"
                    style={balloonProps}

                  />

              <animated.div ref={tailShadowRef} className="tail-shadow" style={{ height: innerHeight, top: top, opacity: opacity }}></animated.div>
        </div>
        
  );
};

//export default SpeechBubble;

export default React.forwardRef(SpeechBubble);
