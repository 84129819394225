import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useTrail, useSpring, a, config, easings } from '@react-spring/web'
import Loader from "../components/Loader";
import TitleSlide from "../components/TitleSlide";
import useTimeout from '../hooks/useTimeout';
import audioMap from '../resources/audio';
import useSound from 'use-sound';
import { useStore } from '../store'


const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children)
  //console.log('items', items)
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    delay: open ? 1000 : 0,
    opacity: open ? 1 : 0,
    translateY: open ? 0 : 20,
   // height: open ? 100 : 0,
    from: { 
        opacity: 0, 
        translateY: 20, 
        //height: 0 
    },
  })
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} className="trailsText" style={style}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  )
}

export default function TitleCard({reveal, ready, permission, setPermission, setGameState}) {
  const [open, set] = useState(reveal)
  const [isReady, setIsReady] = useState(ready)


  const [isSecondButtonLoading, setIsSecondButtonLoading] = useState(
    permission
  );
  const [loadingSpeed, setLoadingSpeed] = useState(1);

  useEffect(() => {

    if (isSecondButtonLoading) {
      setTimeout(() => {
        setIsSecondButtonLoading(false);
      }, 1000 / loadingSpeed);
    }
  }, [isSecondButtonLoading, loadingSpeed]);

  useEffect(() => {

    setIsSecondButtonLoading(permission)

  }, [permission]);

  useEffect(() => {
   // alert('ready is '+ ready.toString())

    setIsReady(ready)

  }, [ready]);



  const audioState = useStore((state) => state.audioState)


  const [playSLLetsPlay] = useSound(audioMap.slLetsPlay, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,
  });

  const [playMarimbaSFX] = useSound(audioMap.marimba, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,
  });


  const btnProps = useSpring(
    {
     // to: [{
    //    scale: (previewActive) ? 1.0 : 0.1},
    //   { top: (previewActive) ?  0 : (window.innerHeight) / 5   }],

      to: {
        width: (permission) ? (ready) ? 360 : 120 : 460,
      },
    //  delay: (show) ? (hover) ? 0 : 600 : 0,
     // duration: 500,//(snapActive) ? 200 : 0,
     // config: config.gentle,
      config:  easings.easeInOutQuart,
      from:{width: 460},
    /*  
      config: {
        duration: 2000,
        easing: easings.easeInOutQuart,
      },
      */
      onRest() {
       // console.log('end snap move')
      },
    }
  )


  

  useEffect(() => {
      console.log('reveal', reveal)
    set(reveal)
  }, [reveal])

  return (
    <div 
      //  onClick={() => set(state => !state)}
        >
      <Trail open={open}>

     {/*   <h1 >Star Glasses</h1> */}
     <img src={"/assets/images/wpb-game-white.png"} className="wpb-game"/>
        <TitleSlide />
        <h3 >Be the Star You Are {permission.toString()}</h3>

        {(permission) ?

         
          <a.button className="btn load-btn"
          key="pre-btn"
          style={
           {
                  width: `460px`,
                  height: `52px`,
                  ...btnProps
               
          }
        
          }
          onPointerOver={(e) => {
            if (isReady) playSLLetsPlay()

          }}
          onClick={(e) => {
            playMarimbaSFX();
           // e.preventDefault();
            //if (permission && ready) setGameState('instGlasses')
            if (permission && isReady) setGameState('freeplay')
          }} 
          >
           { (isReady) ? `Ok, Let's Play!` : <Loader /> }
          </a.button>

        :
          
          <a.button className="btn" 
              key="post-btn"
            
              style={
                {
                      width: `460px`,
                      height: `52px`,
                      

              }
            
              }
              onClick={(e) => {
              // e.preventDefault();
              setPermission(true)

              
              }} 
          >
            Click to Start Your Camera
          </a.button>


        }
       
      </Trail>
    </div>
  )
}
