import {Html, Image as ImageImpl, useScroll, Box, RoundedBox, useTexture} from '@react-three/drei'
import {useFrame, useThree} from '@react-three/fiber'
import {useRef, useState,useEffect, useMemo} from 'react'
import { animated, useTransition } from "@react-spring/three";
import { config, easings } from "@react-spring/web";
//import { Select } from "@react-three/postprocessing"
import * as THREE from 'three'
//import { ScrollControls, Scroll, useScroll } from './ScrollControls'



import Screen from '../components/Screen'
import Thumbnail from '../components/Thumbnail'
import FaceGlasses from './FaceGlasses';
import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'

export default ({ 

    follower, 
    restPos, 
    hidePos, 
    gameMode, 
    previewActive, 
    clickedItem, 
    debug,
    delay,
    menuMode,
    setMenuMode,
    objType,
    playWhooshSFX,
    playMultipopSFX,
    playBoopSFX,
    ...props
  }) => {


  const group = useRef()
  const [hovered, hover] = useState()
  const {width} = useThree((state) => state.viewport)
  const scroll = useScroll();

  const isMobile = useMediaQuery({ query: mediaQueries.mobile })
const isTablet = useMediaQuery({ query: mediaQueries.tablet })
const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

  useEffect(() => {

    if (menuMode === 'glasses' || menuMode === 'scenes'){
      console.log('///// multipop /////'+ menuMode)
      if(playMultipopSFX) playMultipopSFX()
    }
 
 }, [menuMode]);



  function missedClick(){
    setMenuMode('neutral');
    scroll.el.scrollTo({left: 0}) 
   
  }

  const [scenePositions] = useMemo(() => {
   

    const scenePositions = new Array(9)
    .fill()
    .map((_, index) => [
    //   0, 
      isMobile ? 
        width * index 
      : 
     //   width/2 * index, 
        width/2 * index - (width - width/3), 
      0,
      -1
    ])


    return [ scenePositions]
  }, [])



  return (
    <group 
        position-y={-2} 
        onPointerOver={(e) => hover(e.object.parent.name)} 
        onPointerOut={(e) => hover(null)} 
        ref={group} 
        {...props} 
        dispose={null}
    >
   {/* <axesHelper scale={100} /> */}

    { scenePositions.map((pos, index) => (
             <Thumbnail
               key={`0${index}`}
               position={pos}
               objType={objType}
               idx={index}
               total={scenePositions.length}
               pos={pos}
             //  wheelIndex={colors[index]}
             //  rotation-y={((2 * Math.PI * index) / NUM)}
               opacity={1}
               modelIdx={index}
               missedClick={missedClick}
               menuMode={menuMode}
               playBoopSFX={playBoopSFX}
              // setMenuMode={setMenuMode}
             />
    )) }

      
    </group>
  )
}
