import React, { useRef, useEffect, useMemo } from 'react'
import { Canvas, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import Stars from '../components/Stars';
import ThreeActiveArea from '../components/ThreeActiveArea';

import { ScreenSizer, Box, RoundedBox} from "@react-three/drei"

import ScissorsScene from '../components/ScissorsScene';
import ClippedScene from '../components/ClippedScene';



function ClippedMesh() {
  const meshRef = useRef()

  // Define the clipping plane with a normal vector (1, 0, 0) which cuts along the X-axis
  const clippingPlane = useMemo(() => new THREE.Plane(new THREE.Vector3(1, 0, 0), 0), [])

  const { gl } = useThree()

  useEffect(() => {
    // Enable local clipping for the renderer
    gl.localClippingEnabled = true
  }, [gl])

  return (
    <>
      {/* Mesh that will be affected by the clipping plane
      <mesh ref={meshRef} position={[0, 0, 0]}>
        <boxBufferGeometry args={[3, 3, 3]} />
        <meshStandardMaterial
          color="lightblue"
          clippingPlanes={[clippingPlane]}
          side={THREE.DoubleSide}
        />
      </mesh>
 */}
      {/* PlaneHelper to visualize the clipping plane */}
      <primitive object={new THREE.PlaneHelper(clippingPlane, 5, 0xff0000)} />
    </>
  )
}


function ClippedInstancedMesh() {
  const meshRef = useRef()

  // Define a clipping plane with a normal vector (1, 0, 0), which will clip along the X axis
  const clippingPlane = useMemo(() => new THREE.Plane(new THREE.Vector3(1, 0, 0), 0), [])

  const { gl } = useThree()

  useEffect(() => {
    // Enable local clipping for the renderer
    gl.localClippingEnabled = true
  }, [gl])

  // Create instanced mesh with 10 instances
  const instanceCount = 10
  const dummy = new THREE.Object3D() // This will be used to position each instance

  useEffect(() => {
    if (meshRef.current) {
      // Set position for each instance
      for (let i = 0; i < instanceCount; i++) {
        dummy.position.set(Math.random() * 4 - 2, Math.random() * 4 - 2, Math.random() * 4 - 2)
        dummy.updateMatrix()
        meshRef.current.setMatrixAt(i, dummy.matrix) // Apply transformation matrix to each instance
      }
      meshRef.current.instanceMatrix.needsUpdate = true
    }
  }, [instanceCount])

  return (
    <>
    <instancedMesh ref={meshRef} args={[null, null, instanceCount]}>
      {/* Geometry for each instance */}
      <boxBufferGeometry args={[1, 1, 1]} />
      {/* Apply the clipping plane to the material */}
      <meshStandardMaterial
        color="lightgreen"
        clippingPlanes={[clippingPlane]} // Apply the clipping plane to the material
        side={THREE.DoubleSide}
      />
    </instancedMesh>

    <primitive object={new THREE.PlaneHelper(clippingPlane, 5, 0xff0000)} />
  
  </>
  )
}


// Group component with layer, renderOrder, and ignoring z-index (depth)
function LayeredGroup({ color, children, position, layer, renderOrder, ignoreDepth, ignoreMask}) {
    const group = useRef()
  
    // Set the layer and render order for the group
    useEffect(() => {
      group.current.layers.set(layer)
      group.current.renderOrder = renderOrder // Set render order
  
      // If ignoring depth, disable depthTest on the material
      group.current.children.forEach(child => {
        if (child.material) {
          child.material.depthTest = !ignoreDepth
          child.material.colorWrite = !ignoreMask
        }
      })
    }, [layer, renderOrder, ignoreDepth])
  
    return (
      <group ref={group} position={position}>
            {children}
      </group>
    )
  }
  
  // Main Scene
  function Scene({
    debug,
    glassesActive,
    previewActive,
    gameMode,
    clickedItem,
    squareSize,
    menuMode,
    setMenuMode,
    playWhooshSFX,
    playMultipopSFX,
    playBoopSFX,
    matchSquare

  }) {
    const { camera } = useThree()
  
    useEffect(() => {
      // Enable both layers (0 and 1 in this case)
      camera.layers.enable(0) // Default layer
      camera.layers.enable(1) // Second layer

      matchSquare();
    }, [camera])
  
    return (
      <>
        {/* Group on layer 0 with lower renderOrder (renders behind), depth testing is enabled */}
        <LayeredGroup position={[0, 0, 0]} layer={0} renderOrder={1} ignoreDepth={false} ignoreMask={false} >



          
              
            

                <Stars squareSize={squareSize} invert={true} previewActive={previewActive} gameMode={gameMode}/>
            
            

               

                
        </LayeredGroup>
        
        {/* Group on layer 1 with higher renderOrder (always on top), depth testing disabled */}
        <LayeredGroup position={[0, 0, 0]} layer={1} renderOrder={2} ignoreDepth={true} ignoreMask={true}>
            {/*
                <mesh position={[-1, 0, 0]}>
                <boxBufferGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={"blue"} />
                </mesh>
                <mesh position={[1, 0, 0]}>
                <sphereBufferGeometry args={[0.5, 32, 32]} />
                <meshStandardMaterial color={"blue"} />
                </mesh>
                */}
                 <ThreeActiveArea
                    menuMode={menuMode}
                    setMenuMode={setMenuMode}
                    glassesActive={glassesActive}
                    gameMode={gameMode}
                    clickedItem={clickedItem}
                    debug={debug}
                    squareSize={squareSize}
                    playWhooshSFX={playWhooshSFX}
                    playMultipopSFX={playMultipopSFX}
                    playBoopSFX={playBoopSFX}
                    
                />
        </LayeredGroup>
      </>
    )
  }

export default function ThreeSpace({
    debug,
    glassesActive,
    gameMode,
    clickedItem,
    squareSize,
    menuMode,
    setMenuMode,
    playWhooshSFX,
    playMultipopSFX,
    playBoopSFX,
    matchSquare,
    previewActive

}) {

  return (

<Canvas 
id="threeCanvas"
style={{

    // "glassesActive" for mobile, "permission" for desktop????
  zIndex: glassesActive ? 99999 : -1
}}
className={(glassesActive)? 'clickable' : 'no-clickable'}


//sRGB 
camera={{ position: [0, 0, 5], fov: 35 }}
performance= {{
  current: 1,
  min: 0.1,
  max: 1,
  debounce: 200,
  //regress: () => void,
}}
gl={{
 // alpha: true,
  preserveDrawingBuffer: true, // allow image capture
}}
onPointerMissed={() => setMenuMode('neutral') }

>
      <ambientLight intensity={1} />
      <directionalLight position={[10, 10, 10]} />

      <directionalLight position={[1, 2, 1.5]} intensity={0.5}  />
      <hemisphereLight intensity={1.5} groundColor="red" />


{/*
    <ClippedMesh />
*/}
{/*
<RoundedBox
//position={[0,0.5,1]}
        args={[1, 1, 0.01]} // Width, height, depth. Default is [1, 1, 1]
        radius={.05} // Radius of the rounded corners. Default is 0.05
        smoothness={4} // The number of curve segments. Default is 4
        bevelSegments={4} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
        creaseAngle={0.4} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
    >
        <meshPhongMaterial color="red" />
</RoundedBox>
*/}



  

{/*
    <ClippedScene />
*/}


{/*
        <ScissorsScene 
            debug = {debug}
            glassesActive = {glassesActive}
            gameMode = {gameMode}
            clickedItem = {clickedItem}
            squareSize ={squareSize}
            menuMode ={menuMode}
            setMenuMode = {setMenuMode}
            playWhooshSFX = {playWhooshSFX}
            playMultipopSFX = {playMultipopSFX}
            playBoopSFX ={playBoopSFX}
            matchSquare={matchSquare}
        
        />
*/}
   

      <Scene 
         debug = {debug}
         glassesActive = {glassesActive}
         gameMode = {gameMode}
         clickedItem = {clickedItem}
         squareSize ={squareSize}
         menuMode ={menuMode}
         setMenuMode = {setMenuMode}
         playWhooshSFX = {playWhooshSFX}
         playMultipopSFX = {playMultipopSFX}
         playBoopSFX ={playBoopSFX}
         matchSquare={matchSquare}
         previewActive={previewActive}
      
      />

     
    </Canvas>
  )

}