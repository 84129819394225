// @ts-nocheck
import { useGLTF, useTexture, Plane, Box } from '@react-three/drei'
import React, { useRef } from 'react'
import * as THREE from 'three'

const texture1 = `${process.env.PUBLIC_URL}/assets/images/glasses/page5a-frame.png`;
const texture2 = `${process.env.PUBLIC_URL}/assets/images/glasses/page5a.png`;


export default function Model(props) {
  const group = useRef()
  const [bodyTex, armTex, ] = useTexture([texture1, texture2])

  return (
    <group ref={group} {...props} dispose={null} position-y={0.22} scale={.9}>

      <Plane args={[3, 0.99]} 
      position={[0,-0.2,0]}
      >
         <meshBasicMaterial  map={bodyTex} side={THREE.DoubleSide} alphaTest={0.1}
         transparent 
         />
      </Plane>
      <Plane args={[2.22, 0.595]} 
      position={[-1.45,-0.25,-1.03]} 
      rotation={[0, -Math.PI/2, 0]}>
         <meshBasicMaterial transparent map={armTex} side={THREE.DoubleSide} alphaTest={0.1} />
      </Plane>
      <Plane args={[2.22, 0.595]}  
      position={[1.45,-0.25,-1.03]} 
      rotation={[0, -Math.PI/2, 0]}>
         <meshBasicMaterial transparent map={armTex} side={THREE.DoubleSide} alphaTest={0.1} />
      </Plane>
     

    </group>
  )
}

