// @ts-nocheck
import { useGLTF, useTexture, Plane, Box, Svg } from '@react-three/drei'
import React, { useRef } from 'react'
import * as THREE from 'three'

const texture1 = `${process.env.PUBLIC_URL}/assets/images/glasses/page5c-frame.png`;
const svg2 = `${process.env.PUBLIC_URL}/assets/images/glasses/page5c-arm.svg`;


export default function Model(props) {
  const group = useRef()
  const bodyTex  = useTexture(texture1)

  return (
    <group ref={group} {...props} dispose={null} position-y={-0.04} scale={.9}>

      <Svg src={svg2} scale={.0011} position={[-1.48,0.65,-1.9]} rotation={[0, -Math.PI/2, 0]}/>
      <Svg src={svg2} scale={.0011} position={[1.48,0.65,-1.9]} rotation={[0, -Math.PI/2, 0]}/>


      <Plane args={[3.05, 0.745]} 
      position={[0,0.22,0]}
      >
         <meshBasicMaterial  map={bodyTex} side={THREE.DoubleSide} alphaTest={0.1}
         transparent 
         />
      </Plane>

      {/*
      <Plane args={[2.045, 0.92]} 
      position={[-1.505,0.135,-1.0115]} 
      rotation={[0, -Math.PI/2, 0]}>
         <meshBasicMaterial transparent map={armTex} side={THREE.DoubleSide} alphaTest={0.1} />
      </Plane>
      <Plane args={[2.045, 0.92]}  
      position={[1.505,0.135,-1.0115]} 
      rotation={[0, -Math.PI/2, 0]}>
         <meshBasicMaterial transparent map={armTex} side={THREE.DoubleSide} alphaTest={0.1} />
      </Plane>
  */}
     

    </group>
  )
}

