import { Text, Box } from '@react-three/drei'
import React, { useMemo, useState, useEffect, useRef } from 'react'
import { animated, config, useSpring } from '@react-spring/three'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import {useWheel, useStore } from '../store'
import useTimeout from '../hooks/useTimeout';
import audioMap from '../resources/audio';
import useSound from 'use-sound';
import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'


import { useControls } from 'leva'


// eslint-disable-next-line import/no-anonymous-default-export
export default ({ 
  children, 
  follower, 
  chosenGlasses,
  setChosenGlasses,
  restPos, 
  hidePos, 
 // gameMode, 
  glassesActive, 
  clickedItem, 
  debug,
  delay = 200,
  isolateGlass
}) => {
  const myMesh = React.useRef()
  const [xpos, setXpos] = useState(0)
  const [ypos, setYpos] = useState(0)
  const [zpos, setZpos] = useState(10)
  const [xrot, setXrot] = useState(0)
  const [yrot, setYrot] = useState(0)
  const [zrot, setZrot] = useState(0)

  const isMobile = useMediaQuery({ query: mediaQueries.mobile })
  const isTablet = useMediaQuery({ query: mediaQueries.tablet })
  const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

  const [xrestpos, setXrestpos] = useState(hidePos[0])
  const [yrestpos, setYrestpos] = useState(hidePos[1])
  const [zrestpos, setZrestpos] = useState(hidePos[2])

  const glassFollowActive = useWheel((s) => s.glassFollowActive)
  const setGlassFollowActive = useWheel((s) => s.setGlassFollowActive)
  const {setMainIndex} = useStore((state) => state.actions)
  const audioState = useStore((state) => state.audioState)

  const positionRef = useRef(new THREE.Vector3());

  const [iso, setIso] = useState(false)

  const [playWhooshSFX] = useSound(audioMap.whoosh2, {
    volume: (audioState) ? 1 : 0,
  });

  const [timeoutResetSelection] = useTimeout(() => {

    setMainIndex(null)
    
  }, 600);


  useEffect(() => {
    console.log('glassesActive', glassesActive)

    if (glassesActive ){
     
        setXrestpos(restPos[0])
        setYrestpos(restPos[1])
        setZrestpos(restPos[2])
 
    }else{
      setXrestpos(hidePos[0])
      setYrestpos(hidePos[1])
      setZrestpos(hidePos[2])
    }
    
 }, [glassesActive])




  const speed = useMemo(() => {

    return 400;//Math.random() * 5000 + 1000

    // return Math.floor(Math.random() * (500 - 100 + 1)) + 100;
  }, [])

  //const [active, setActive] = useState(false)



  const { scale, position, rotation } = useSpring({

  //  scale: iso ? 0.05 : scaleAdjust,
    scale: isMobile ? 2.5 *.65 : 2.5,
    position: [xpos, ypos, zpos],
/*
    position: glassFollowActive
      ? [
          xpos, //follower[0].position.x,
          ypos , //follower[0].position.y,
          zpos , // follower[0].position.z,

        ]
      : [xrestpos, 1, (iso) ?  zpos : zpos],
     // : [xrestpos, 1, (iso) ?  zpos : zpos],
    // position: active ? [0, 0, -5] : [-5, 0, -20],
    */
    rotation: glassFollowActive
      ? [
          xrot, //0,
          yrot,// + THREE.MathUtils.degToRad(720),
          zrot, //0
        ]
      : [0, 0, 0],

   // config: glassFollowActive ? { mass: 0.1, friction: 10, tension: 2000 } : config.wobbly,
    config: { mass: 0.1, friction: 10, tension: 2000 },
   immediate: true,
  //  delay: active ? 0 : (previewActive ? delay : 0),
    delay: 0,
    onStart: () => {
     // console.log('start')
    },

    onRest: () => {
     // console.log('rest')
    //  if (active) {
     //   console.log('snap')
    //  }
      //alert('spun')
    },
  
  })

  const { zGlasses } = useSpring({

    zGlasses: glassFollowActive
      ? 0// + zAdjust
      : 30,
    config: config.slow,
  //  delay: glassFollowActive ? 1000 : 0
   // config: glassFollowActive ? config.slow :  { mass: 0.1, friction: 10, tension: 2000 } ,
  
  })

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime()
   // console.log('follower', follower)
   // console.log('glassFollowActive', glassFollowActive)
    if (follower !== null ){
       // console.log('follower.position.x', follower.position.x)

     //  positionRef.current.set(xpos+0.5, ypos-0.5, dzRef.current * -1 );
     

     

      if (!glassFollowActive) {
        //  setXrestpos(xrestpos + Math.sin(clock.getElapsedTime() / 20))
        setYrestpos(yrestpos + Math.sin(clock.getElapsedTime()*5) / speed)
        setZrestpos(50 + Math.sin(clock.getElapsedTime()) / speed)
        //   setZrestpos(zrestpos + Math.sin(clock.getElapsedTime() / 20))
      }else{
        setXpos(follower.position.x)
        setYpos(follower.position.y)
        setZpos(follower.position.z) //+ 2??
        setXrot(follower.rotation.x)
        setYrot(follower.rotation.y)
        setZrot(follower.rotation.z)
      }

      
    }
    

  })

  function clickGlass(e) {
    
    //console.log(follower)
   // console.log('e',e.object.uuid)
    setGlassFollowActive(false)
    timeoutResetSelection();
    playWhooshSFX()
   // alert('follow')


   // setChosenGlasses(children.key)
   // console.log('interactiveModel:', children.key)
 
//    clickedItem();
    //if (follower !== null) setActive(!active)
  }

  return (
    <animated.mesh
      //  {...props}
     scale={scale}
      position={position}
      rotation={rotation}
     // visible={(glassFollowActive && gameMode==='polaroid') ? false : true}
      visible={(glassFollowActive) ? true : false}
      //  onClick={() => setActive(!active)}
      onClick={(e) => clickGlass(e)}
      ref={myMesh}
      
    >
      <animated.group 
   //   position={[0, yAdjust, zAdjust] } 
      position-z={zGlasses}
     // scale={1.5,1,1}
      >
        {children}
      </animated.group>

      {/* <Box /> */}

      {debug &&
        <axesHelper args={[5,5,5]}/>
      }
      {/*
      <BlackPurpleGlasses
        //scale={[0.01, 0.01, 0.01]}
        //  position={[0, 0.2, .4]}
        scale={[0.8, 0.8, 0.8]}
        position={[0, 0.25, 0.5]}
        renderOrder={2}
      />
      */}
    </animated.mesh>
  )
}
