
import React, { useState, useEffect } from 'react'
import { useSpring, useTransition, animated, config, easings} from '@react-spring/web'
//import styles from './styles.module.css'

const slides = [
      '',
]


const SnapshotPanel = ({exportSnapshot, bgImg = '', gameState, size =500, counter = 0, hover}) =>{
//export default function BackgroundPanel({bkgd}) {
  const [index, set] = useState(counter)
  const [show, setShow] = useState(false)

  const snapshotProps = useSpring(
    {
     // to: [{
    //    scale: (previewActive) ? 1.0 : 0.1},
    //   { top: (previewActive) ?  0 : (window.innerHeight) / 5   }],

      to: {
        scale: (show) ? 1.0 : 1.5,
        opacity: (show) ? 1.0 : 0,
        rotate: (show) ? (hover) ? 0 : -5 : 5,
        translateY: (show) ?  0 : (-window.innerHeight) / 5   },
      delay: (show) ? (hover) ? 0 : 600 : 0,
     // duration: 500,//(snapActive) ? 200 : 0,
      config: config.gentle,
     // config:  easings.easeInOutQuart,
      from:{scale:1.5, translateY: 0, rotate: 5, opacity: 0},
    /*  
      config: {
        duration: 2000,
        easing: easings.easeInOutQuart,
      },
      */
      onRest() {
        console.log('end snap move')
      },
    }
  )

  useEffect(() => {
    slides.push(bgImg)
    set(state => (state + 1))
 }, [bgImg])

 useEffect(() => {
     console.log('snap state', gameState)
    if (gameState==='polaroid'){
        setShow(true)
    }else{
        setShow(false)
    }
 }, [gameState])


 // const [backImg, setBackImg] = useState(bkgd)
  const transitions = useTransition(index, {
    key: index,
    from: { 
        opacity: 0,
        scale: 1.1
    },
    enter: { 
        opacity: 1,
        scale:1
     },
    leave: { 
        opacity: 0,
        scale: 1.05
     },
   // config: { duration: 600 },
   config: {
       duration: 500,
      easing: easings.easeInOutBack,
   },
 //   config: config.gentle,
   // onRest: (_a, _b, item) => {
     // if (index === item) {
       //  set(state => (state + 1) % slides.length)
     // }
  //  },
    exitBeforeEnter: true,
  })


  return (
  
    <animated.div className="snapshot-panel" id="polaroid" style={{ 
      width: size, 
      height: size +100, 
      ...snapshotProps
    }}
    // onClick={exportSnapshot}
    >
    <div className="flex fill center">
       {/* <div id="polaroid"></div> */}
      {transitions((style, i) => (
        <animated.div
          className="bg round-edge"
          
          style={{
            ...style,
            width: '100%', 
            height: size,
      
           // backgroundImage: 'url('+bkgd+')',
         //   backgroundImage: 'url('+bgImg+')',//`url(https://images.unsplash.com/${slides[i]}?w=1920&q=80&auto=format&fit=crop)`,
           // backgroundImage: `url(https://images.unsplash.com/${slides[i]}?w=1920&q=80&auto=format&fit=crop)`,
           backgroundImage:`url(${slides[i]})`
          }}
        />
      ))}

        <h4 className="snapTag">Be the Star You Are!</h4>
        <img className="snapTag-site" src="/assets/images/wpbtext-findfun-small.png" />
       {/* <h6 className="snapTag-site">Find Fun at WayPastBooks.com</h6> */}
    </div>
    
    </animated.div>
  )
}

//export default React.memo(BackgroundPanel);
export default SnapshotPanel;
