import {Html, Image as ImageImpl, useScroll, Box} from '@react-three/drei'
import {useFrame, useThree} from '@react-three/fiber'
import {useRef, useState, useEffect} from 'react'
import * as THREE from 'three'
//import { ScrollControls, Scroll, useScroll } from './ScrollControls'
import { useWheel, useStore, glassesArray } from '../store'
import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'

import InteractiveModel from '../components/InteractiveModel'



function Box1() {
    const { size, viewport } = useThree();
    
  //  console.log('1st Viewport: ' + viewport.width)
  //  console.log('1st size: ' + size.width)
    return (
      <mesh position={[0, 1.1, -2]}>
        <planeGeometry />
        <meshBasicMaterial color="orange" />
      </mesh>
    )
  }
  
export default ({ 

    follower, 
    chosenGlasses,
    setChosenGlasses,
    restPos, 
    hidePos, 
   // gameMode, 
    glassesActive, 
    clickedItem, 
    debug,
    delay,
    isolateGlass
  }) => {

  //const {width} = useThree((state) => state.viewport)
  const viewport = useThree(state => state.viewport)
  //console.log('VIEWPORT', viewport)
  const isMobile = useMediaQuery({ query: mediaQueries.mobile })
const isTablet = useMediaQuery({ query: mediaQueries.tablet })
const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

  const depth = -18;
  const column1 = -8;
  const column2 = -5;
  const column3 = 5;
  const column4 = 8;

  const glassesIndex = useWheel((s) => s.glassesIndex)
  const mainIndex = useStore((state) => state.mainIndex)

  const offsetY = 0;//isMobile ? (0.3 * viewport.height) : 0;

  /*
  useEffect(() => {

    console.log('Main Index is '+ mainIndex)
    
  }, [mainIndex])
  

*/


  // console.log('profiles', profiles)

 // const numDir = profiles.length
  //console.log('profiles length', numDir)

  return (
    <group 
      position-y={offsetY}
    >
   
  <InteractiveModel 
    follower={follower} 
    chosenGlasses={chosenGlasses}
    setChosenGlasses={setChosenGlasses}
    clickedItem={clickedItem}
  
    
   // restPos={[-2.8, 0, -9]}
  //  gameMode={gameMode}
    glassesActive={glassesActive}
    delay={200}
    hidePos={[-viewport.width, -15, -16]}
    restPos={[0, 4.5, depth]}
  
    debug={debug}
    isolateGlass={isolateGlass}
    >

        {glassesArray[mainIndex] }
   {/*
    <BlackTopRimGlasses
     // key="hearts"
    //  scale={[1.85, 1.85, 2.75]}
    //  position={[0, 0.15, -0.1]}
     // position={[0, .3, 1.1]}
   //  position={[0, 0, 0]}
      renderOrder={2}
     
    />
  */}

  </InteractiveModel>
     
  </group>

)
}

