import React, { useMemo, useState, useEffect } from "react";
import { useTransition, animated, config, easings} from '@react-spring/web'


const Message = ({ message='', fastOut }) => {
  const [msgArr, setMsgArr] = useState(message);
 // const [fastOut, setFastOut] = useState(false);
  const [headline, setHeadline] = useState(false);

 /*
  useEffect(() => {

    setFastOut(!fastOut);
  }, [message]);
  */

  const transitions = useTransition(message, {
    key: message,
    from: { 
        opacity: 0,
        scale: 1.1
    },
    enter: { 
        opacity: 1,
        scale:1
     },
    leave: { 
        opacity: 0,
        scale: 1.05
     },
   // config: { duration: 600 },
   delay: fastOut ? 0 : 500,
   config: {
       duration: 500,
      easing: fastOut ? easings.easeOutCubic : easings.easeInOutBack,
   },
 //   config: config.gentle,
   // onRest: (_a, _b, item) => {
     // if (index === item) {
       //  set(state => (state + 1) % slides.length)
     // }
  //  },
    exitBeforeEnter: true,
  })

  /*
  const items = useMemo(
    () =>  
      msgArr.split("").map((letter, index) => ({
        item: letter,
        key: index
      })),
    [msgArr]
    );
  const transitions = useTransition(items, item => item.key, {
    trail: 20,
   // from: { display: "none" },
   // enter: { display: "" }
    from: { opacity:0 },
    enter: { opacity:1 }
  });
  */

  return (
    <div className="DialogMessage">
      {/*msgArr*/}

      {transitions((style, item, i) => (
        <animated.div
          //className="bg round-edge"
          key={i}
          style={{
            ...style,

          }}

        >
             {item}
        </animated.div>
      ))}

      
      
      {/*transitions.map(({ item, props, key }) => {
        return (
          <animated.span key={key} style={props}>
           {  item.item }
           
          </animated.span>
        );
         })
        */}
    
    </div>
  );
};

export default Message;
