/*
import React, { useState, useEffect, useCallback } from 'react';
import useTimeout2 from '../hooks/useTimeout2';


const SequentialActions = () => {
    const [step, setStep] = useState(0);
    const [isCancelled, setIsCancelled] = useState(false);
  
    const nextStep = useCallback(() => {
      if (!isCancelled) {
        setStep((prevStep) => prevStep + 1);
      }
    }, [isCancelled]);
  
    const { set: setTimeoutStep, clear: clearTimeoutStep } = useTimeout2(nextStep, 1000);
  
    const handleCancel = useCallback(() => {
      setIsCancelled(true);
      clearTimeoutStep();
    }, [clearTimeoutStep]);
  
    useEffect(() => {
      if (step < 5 && !isCancelled) {
        setTimeoutStep();
      }
    }, [step, setTimeoutStep, isCancelled]);
  
    useEffect(() => {
      return () => {
        clearTimeoutStep();
      };
    }, [clearTimeoutStep]);

  return (
    <div style={{
        position: 'absolute',
        top: '100px',
        left: '30px',
        width: '300px',
        height: '80px',
        backgroundColor: 'pink'

    }}>
      <div>Current Step: {step}</div>
      <button onClick={handleCancel}>Cancel</button>
    </div>
  );
};

export default SequentialActions;
*/
import React, { useState, useCallback, useEffect } from 'react';
import useTimeout2 from '../hooks/useTimeout2';
import useTimeout from '../hooks/useTimeout';
import useSound from 'use-sound';

import audioMap from '../resources/audio';
import { useStore, messages } from '../store'

const SequentialMessages = ({menuMode, gameMode}) => {
  const [step, setStep] = useState(0);
  const [isCancelled, setIsCancelled] = useState(false);
  const [toldPhoto, setToldPhoto] = useState(false);
  const audioState = useStore((state) => state.audioState)

  const [completed, setCompleted] = useState(false);

  const pickedGlass = useStore((state) => state.pickedGlass)
  const pickedScene = useStore((state) => state.pickedScene)
  const pickedPhoto = useStore((state) => state.pickedPhoto)
 // const balloonActive = useStore((state) => state.balloonActive)

  const balloonActive = useStore((state) => state.balloonActive)
  const balloonMessage = useStore((state) => state.balloonMessage)
  const balloonAudio = useStore((state) => state.balloonAudio)

  const {setBalloonState, setBalloonActive} = useStore((state) => state.actions)

 //const [play, { stop }] = useSound

 //const [playJAPickShades , end1] = useSound(audioMap.jaPickShades, {
const [playJAPickShades , {stop: stop1}] = useSound(audioMap.jaPickShades, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,
   // volume: 1
  });
  
  const [playSLPickShades , {stop: stop2}] = useSound(audioMap.slPickShades, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,
  });
  
  const [playChangeBkgd , {stop: stop3}] = useSound(audioMap.changeBkgd, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,
  });
  
  const [playJAClickCamera , {stop: stop4}] = useSound(audioMap.jaClickCamera, {
    volume: (audioState) ? 1 : 0,
    interrupt: true
  });
  
  const [playSLSendBestie , {stop: stop5}] = useSound(audioMap.slSendBestie, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,
  });
  
  const [playSLSend , {stop: stop6}] = useSound(audioMap.slSend, {
    volume: (audioState) ? 1 : 0,
    interrupt: true,
  });

  //sound1.stop();

 const quotes =[
  function(){console.log('zero')},
    playSLPickShades,
   // playChangeBkgd,
    function(){console.log('scene')},
    playJAClickCamera,
    playSLSendBestie,
    playSLSend

 ]

 function killAll(){
    stop1();
    stop2();
    stop3();
    stop4();
    stop5();
    stop6();
 }

 const [timeoutLast, clear] = useTimeout(() => {
  
 stop5()
  playSLSendBestie();
  //handleNext()
  handleNextStep(4)

}, 4500);

const [timeoutFirst, clearFirst] = useTimeout(() => {

  //handleNext()
  handleNextStep(1)
// playSLPickShades()
  playSLPickShades()

}, 1000);

const [timeoutScene, clearScene] = useTimeout(() => {
  
  playChangeBkgd()

//  alert('play bkgd')
  handleNextStep(2) //to scenes

}, 1000); 

const [timeoutPhoto, clearPhoto] = useTimeout(() => {
  stop2()
  stop3()
  playJAClickCamera()
  setToldPhoto(true)

//  alert('play bkgd')
  handleNextStep(3) //to scenes

}, 2000); 

  const nextStep = useCallback(() => {
    console.log('NEXT step >>>>>>')

    setBalloonActive(false)
    /*
    
    if (!isCancelled && step < messages.length) {
      

      setStep((prevStep) => prevStep + 1);
    }
      */
  }, [isCancelled, step, messages.length]);

  const { set: setTimeoutStep, clear: clearTimeoutStep } = useTimeout2(nextStep, 2500);

  function handleToggle(){
    console.log('toggle: balloonActive: '+ balloonActive)
    setBalloonState(!balloonActive, 'xoxo: '+ !balloonActive, 0)
  }

  function handleNext(){
    clearTimeoutStep();
    
    setBalloonActive(false);
    killAll()
    clearTimeoutStep();

    console.log('NEXT: step: '+ step)

    if ((step) < messages.length && !isCancelled) {
      
      setStep((prevStep) => prevStep + 1);
      quotes[step+1]();

      
      if ((step+1) < messages.length) {

        console.log('step is '+step)

      
          setTimeoutStep()
          setBalloonState(true, messages[step+1] , 0)
        
      }
    }
  }

  function handleNextStep(num){
   // killAll()
    clearTimeoutStep();
    
    setBalloonActive(false);
   // 
    clearTimeoutStep();

    console.log('NEXT: step: '+ step)

    if ((step) < messages.length && !isCancelled) {
      
      setStep(num);
    //  quotes[num]();

      
      if ((num) < messages.length) {
        setTimeoutStep()
        setBalloonState(true, messages[num] , 0)
      }
    }
  }

  const handleCancel = useCallback(() => {
    setIsCancelled(true);
    setStep(0)
    setBalloonActive(false);

   
    killAll()
   // stopAudio2()
    console.log('playSLPickShades',playSLPickShades)
    clearTimeoutStep();

    
  }, [clearTimeoutStep]);

  const handleReset = useCallback(() => {
    setIsCancelled(false);
    clearTimeoutStep();
    setStep(0)
    killAll();
  
    
    setTimeoutStep();
     quotes[step]();

  }, [clearTimeoutStep, setTimeoutStep]);

  useEffect(() => {
    if (step < messages.length && !isCancelled) {
      setTimeoutStep();
     // quotes[step]()
      quotes[step]();
     // quotes[1]();
     
    }
  }, [setTimeoutStep, isCancelled, messages.length]);

  useEffect(() => {
    return () => {
      clearTimeoutStep();
    };
  }, [clearTimeoutStep]);


  useEffect(() => {

    if (!completed){

      if (gameMode ==='freeplay'){
    
        console.log('SPEECH: mode: '+ gameMode)

            if (menuMode==='glasses'){

              if(!pickedGlass){
                setBalloonActive(false);
                stop3()
                stop4()
                timeoutFirst()
              }

            }else if (menuMode==='scenes'){

              if(!pickedScene){
                stop2()
                stop4()
                setBalloonActive(false);
                timeoutScene()

              

                }
            }


            if (pickedGlass && pickedScene && !pickedPhoto && !toldPhoto){
             // handleNextStep(3) // to snapshot 
              setBalloonActive(false);
            
              timeoutPhoto()
            }


    /*
        if (menuMode==='glasses'){
          

          if(pickedGlass && !pickedScene && !pickedPhoto){
           // handleNext()
            handleNextStep(2) //to scenes
        //   playSLPickShades()
            console.log('Audio: 2')
    

          
      
          }else if (pickedGlass && pickedScene && !pickedPhoto){
    
            handleNextStep(3) // to snapshot  - do i need this??
           // handleNext()
      
          }else if(!pickedGlass && !pickedScene && !pickedPhoto){ 
      
           // handleNextStep(1)

            timeoutFirst()
           
            console.log('Audio: 1')
        
          }
          
        }else if (menuMode==='scenes'){

          if(pickedGlass && !pickedScene && !pickedPhoto){
            playChangeBkgd()
        // alert('scenes')
          }else if (pickedGlass && pickedScene && !pickedPhoto){
          //  handleNext()
            handleNextStep(3) // to snapshot
          }

            */
   //   }

      

    
    
    
      }else if (gameMode ==='polaroid'){
    
    
        
      //  handleNextStep(4)
        timeoutLast()
        setCompleted(true)
        console.log('Audio: 1')
      
      }
    }
    
  
  
      return () => {}
      
    }, [gameMode, pickedGlass, pickedScene, pickedPhoto, menuMode]);

  return null

  return (
    <div style={{
        position: 'absolute',
        top: '100px',
        left: '30px',
        width: '300px',
        height: '80px',
        padding: '10px',
        backgroundColor: 'pink',
        zIndex: 9999999999999999,

    }}>
      <div>{step} - {step < messages.length ? messages[step] : 'No more messages'}</div>
      <button onClick={handleCancel}>Cancel</button>
      <button onClick={handleReset}>Reset</button>
      <button onClick={handleToggle}>Toggle</button>
     {/* <button onClick={handleNext}>Next</button> */}
    </div>
  );
};

export default SequentialMessages;