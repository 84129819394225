// @ts-nocheck
import { useGLTF, useTexture, Plane, Box, Svg } from '@react-three/drei'
import React, { useRef, useEffect } from 'react'
import * as THREE from 'three'

const texture1 = `${process.env.PUBLIC_URL}/assets/images/glasses/page1b-frame.png`;



const svg2 = `${process.env.PUBLIC_URL}/assets/images/glasses/page1b-arm.svg`;


export default function Model(props) {
  const group = useRef()
  const bodyTex  = useTexture(texture1)

  return (
    <group ref={group} {...props} dispose={null} position-y={0.17} scale={.9}>

      <Svg src={svg2} scale={.001} position={[-1.4,0.45,-2.13]} rotation={[0, -Math.PI/2, 0]}/>
      <Svg src={svg2} scale={.001} position={[1.4,0.45,-2.13]} rotation={[0, -Math.PI/2, 0]}/>
   

      <Plane args={[3.28, 1.115]} 
      position={[0,0,0]}
      >
         <meshBasicMaterial  map={bodyTex} side={THREE.DoubleSide} alphaTest={0.01}
         transparent 
         />
      </Plane>

    </group>
  )
}

