// @ts-nocheck
import { useGLTF, useTexture, Plane, Box, Svg } from '@react-three/drei'
import React, { useRef } from 'react'
import * as THREE from 'three'

const texture1 = `${process.env.PUBLIC_URL}/assets/images/glasses/page1a-frame.png`;
const svg2 = `${process.env.PUBLIC_URL}/assets/images/glasses/page1a-arm.svg`;


export default function Model(props) {
  const group = useRef()
  const bodyTex  = useTexture(texture1)

  return (
    <group ref={group} {...props} dispose={null} position-y={0.15} scale={.85}>

      <Svg src={svg2} scale={.001} position={[-1.51,0.41,-1.825]} rotation={[0, -Math.PI/2, 0]}/>
      <Svg src={svg2} scale={.001} position={[1.51,0.41,-1.825]} rotation={[0, -Math.PI/2, 0]}/>

      <Plane args={[3.28, 1.115]} 
      position={[0,0,0]}
      >
         <meshBasicMaterial  map={bodyTex} side={THREE.DoubleSide} alphaTest={0.1}
         transparent 
         />
      </Plane>


    </group>
  )
}
