const debug = {
    age: null,
    log: false,
  }
  
const mediaQueries = {
    mobile: 'screen and (max-width: 767px)',
    tablet: 'screen and (min-width: 768px) and (max-width: 991px)',
    desktop: 'screen and (min-width: 992px)',
  }
  export {
    debug,
    mediaQueries,
  }