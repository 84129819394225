import React, { useState, useEffect } from 'react'
import { useTrail, a, config } from '@react-spring/web'

import SnapButton from '../components/SnapButton';
import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'

const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children)
 // console.log('control items', items)
  const trail = useTrail(items.length, {
   // config: { mass: 50, tension: 2000, friction: 200 },
   // config: config.wobble,
    config: config.gentle,
    delay: 0,//open ? 1000 : 0,
    opacity: open ? 1 : 0,
    translateY: open ? 0 : 100,
   // height: open ? 100 : 0,
    from: { 
        opacity: 0, 
        translateY: 100, 
        //height: 0 
    },
  })
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} style={style}>
          {items[index]}
        </a.div>
      ))}
    </div>
  )
}

export default function SelfieControls({
    reveal, 
    gameMode,
    menuMode,
    setMenuMode,
    prevBkgd, 
    exportAsPicture, 
    nextBkgd,
    selfieSize,
    playPopSFX,
    setKill,
    kill

}) {
  const [open, set] = useState(reveal)

  const isMobile = useMediaQuery({ query: mediaQueries.mobile })
const isTablet = useMediaQuery({ query: mediaQueries.tablet })
const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

  

  useEffect(() => {
    //  console.log('reveal', reveal)
    set(reveal)
  }, [reveal])

  return (
    <div 
      className="button-bar show" 
      data-html2canvas-ignore 
    
      style={{
      //  bottom: (window.innerHeight - selfieSize) / 2,
        bottom: isMobile ? 'calc(50dvh - 210px)' : (window.innerHeight - selfieSize) / 2,
        width: selfieSize
      }}
      //  onClick={() => set(state => !state)}
        >
      <Trail open={open}>
          {/*
        <span>Lorem</span>
        <span>Ipsum</span>
        <span>Dolor</span>
        <span>Sit</span>
          */}
          
       {/* <button className="arrow-btn left" onClick={prevBkgd}></button> */}
        <button 
          className={(menuMode === 'glasses') ?  "phase-btn phase-selected"  :  "phase-btn" }
        
        onClick={() => {  
          playPopSFX();
        
          if(menuMode !== "glasses"){
            setMenuMode('glasses')
      
            
          }else{
            setMenuMode('neutral')
          }
        }}>GLASSES</button>
        <SnapButton 
          key="snapbtn"
          gameMode={gameMode}
          setMenuMode={setMenuMode}
          exportAsPicture={exportAsPicture}
          timerTrigger={null}
          setKill={setKill}
          kill={kill}
        />
      {/*  <button className="snapButton" onClick={exportAsPicture}><Countdown /></button> */}
       {/* <button className="arrow-btn" onClick={nextBkgd}></button> */}
        <button 
           className={(menuMode === 'scenes') ?  "phase-btn phase-selected"  :  "phase-btn" }
       // className="phase-btn" 
        onClick={() => {  

          playPopSFX();
          if(menuMode !== "scenes"){
            setMenuMode('scenes')
   
          }else{
            setMenuMode('neutral')
          }
      
        }}>SCENES</button>
      </Trail>
    </div>
  )
}


